import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './alert.css';

const Alert = ({ message, visible, type, onClose }) => {
  const [show, setShow] = useState(visible);

  useEffect(() => {
    if (visible) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        onClose(); // Reset the visible state in the parent component
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  const alertClass = type === 'success' ? 'alert-success' : 'alert-danger';

  return (
    <>
      {show && (
        <div className={`alert ${alertClass} alert-dismissible fade show koko`} role="alert">
          {message}
        </div>
      )}
    </>
  );
};

export default Alert;
