import React,{Component} from "react";
class Compte extends Component{
    render(){
        return(
            <div>
                Compte
            </div>
        )
    }
}
export default Compte;