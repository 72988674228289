import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './axiosConfig';
import { Form, Table, Button, Pagination, Modal } from 'react-bootstrap';

import ReactToPrint from 'react-to-print';
import ListProduct from './ListProduct';
import BonSort from './print/bonSort';
import './listprod.css';
import { FaPrint } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import Error from './Error';
import { FaArrowUp,FaArrowDown } from "react-icons/fa";

const ListeDeBonDeSortie = () => {
  const [bonComs, setBonComs] = useState([]);
  const [selectedBonCom, setSelectedBonCom] = useState(null);
  const [details, setDetails] = useState([]);
  const [services, setservices] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModaldet, setShowDeleteModaldet] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [bonComToDelete, setBonComToDelete] = useState(null);
  const [bonComToDeletedet, setBonComToDeletedet] = useState(null);
  const [dateselected, setdateselected] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [modifiedQuantity, setModifiedQuantity] = useState('');
  const [selectprod, setselectprod] = useState(null);
  const [selectedservice, setselectedservice] = useState(null);
  
  const [selectbonnum, setselectbonnum] = useState(null);
  const [selectbonid, setselectbonid] = useState(null);
  const [showListProd, setshowlistprod] = useState(false);
  const [printTrigger, setPrintTrigger] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [selectservice, setselectservice] = useState(4);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [mesagerror, setmesagerror] = useState();
  const [indextable, setindextable] = useState('asc');
  const [order, setorder] = useState('desc');
  

  const componentRef = useRef();

  const handleAddProductTodet = (product) => {
    
    setDetails((details) => [ ...details,product]);
    
  };

  


  const handleSaveaddoneprod = (id_prod, quent, desig_prod) => {
    const token = localStorage.getItem('token');
    axiosInstance.post('/add_one_prod_sort', { id_prod, quent, id_bon: selectbonid }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        handleAddProductTodet({ id: id_prod, quent, desig_prod,iddet:response.data.id });
      })
      .catch(error => {
        
        console.log(error.response.data.error);
        setmesagerror(error.response.data.error);
        setshowerror(true);
      });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/bon_sort', { params:{id_service:selectedservice,page: currentPage,order:order},headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setDetails([]);
        setBonComs(response.data.data);
        if (response.data.data.length > 0) {
          
          setSelectedBonCom(response.data.data[0]);
          fetchDetails(response.data.data[0].id);
          setindextable(response.data.data[0].id);

          setselectbonid(response.data.data[0].id) ; 
          setdateselected(response.data.data[0].date);
          setselectbonnum(response.data.data[0].num);
          console.log(response.data.data[0]);
          setselectservice(response.data.data[0].desig);
        setSelectedBonCom(response.data.data[0]);
        console.log("hhhhh");
        setTotalPages(response.data.last_page);
        
        }
      })
      .catch(error => {
        console.error('There was an error fetching the bon de commande!', error);
      });  
  }, [selectedservice,currentPage,order]);



  
  useEffect(() => {
    
    const token = localStorage.getItem('token');
    axiosInstance.get('/etabserv', { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
      setservices(response.data);
      console.log("ffff");
      
    })
    .catch(error => {
      console.error('There was an error fetching the bon de commande!', error);
    });  

  }, []);






  const fetchDetails = (bonComId) => {
    setDetails([]);
    const token = localStorage.getItem('token');
    axiosInstance.get(`/bon_sort_det/${bonComId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        
        setDetails(response.data);
        console.log("detaille bon de sort",response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the details!', error);
        
      });
  };

  const handleRowClick = (bonCom) => {
    if(selectbonid===bonCom.id){
      
    }
    else{
      setselectbonid(bonCom.id)  
      setdateselected(bonCom.date)
      setselectbonnum(bonCom.num)
      setselectservice(bonCom.desig)
    setSelectedBonCom(bonCom);
    fetchDetails(bonCom.id);
    }
  };

  const handleDeleteClick = (bonCom) => {
    setSelectedBonCom(bonCom); // Show details before delete
    setBonComToDelete(bonCom);
    setShowDeleteModal(true);
  };

  const handleDeleteClickdet = ($bonCom) => {
    // setSelectedBonCom(bonCom); // Show details before delete
    setBonComToDeletedet($bonCom);
    setShowDeleteModaldet(true);
  };

  const confirmDelete = () => {
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/bon_sortt/${bonComToDelete.id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
         const updatedBonComs = bonComs.filter(bc => bc.id !== bonComToDelete.id);
        console.log("Filtered bonComs", updatedBonComs);
        setBonComs(updatedBonComs);
        
        setShowDeleteModal(false);


        setselectbonid(null) ; 
        setdateselected(null);
        setselectbonnum(null);
        setSelectedBonCom([]);
        setindextable(null);
        setDetails([]);
        
        if (bonComs.length > 0) {
          
          setSelectedBonCom(updatedBonComs[0]);
          fetchDetails(updatedBonComs[0].id);
          setindextable(updatedBonComs[0].id);

          setselectbonid(updatedBonComs[0].id) ; 
          setdateselected(updatedBonComs[0].date);
          setselectbonnum(updatedBonComs[0].num);
          console.log(updatedBonComs[0]);
          setselectservice(updatedBonComs[0].desig);
        setSelectedBonCom(updatedBonComs[0]);
        console.log("hhhhh");                
        }


      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
      });
  };

  const confirmDeletedet = () => {
    
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/bon_sort_det/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setDetails(details.filter(bc => bc.iddet !== bonComToDeletedet));
        console.log("detail after delete",details)
        setShowDeleteModaldet(false);
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
        setmesagerror(error.response.data);
        setshowerror(true);
      });
  };

  const handleModifyClick = (detail) => {
    setSelectedDetail(detail);
    setModifiedQuantity(detail.quent);
    setShowModifyModal(true);
  };

  const confirmModify = () => {
    const token = localStorage.getItem('token');
    axiosInstance.put(`/bon_sort_det/${selectedDetail.iddet}`, { quent: modifiedQuantity }, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const updatedDetails = details.map(det =>
          det.iddet === selectedDetail.iddet ? { ...det, quent: modifiedQuantity } : det
        );
        setDetails(updatedDetails);
        setShowModifyModal(false);
        console.log(response.data);
      })
      .catch(error => {
        console.error('There was an error updating the bon de commande detail!', error);
        setmesagerror(error.response.data.error);
        setshowerror(true);
      });
  };

  const handlePrintClick = (bonCom) => {
    if (selectedBonCom && selectedBonCom.id === bonCom.id) {
      // If the clicked row is already selected, just trigger print
      setPrintTrigger(true);
    } else {
      // Otherwise, fetch details and then trigger print
      fetchDetails(bonCom.id);
      setSelectedBonCom(bonCom);
      setPrintTrigger(true);
    }
  };

  useEffect(() => {
    if (printTrigger) {
      setPrintTrigger(false);
      document.getElementById('print-btn').click();
    }
  }, [printTrigger, details]);

  const handelservicechange = (e) => {
    setselectedservice(e.target.value);
    // setCurrentPage(1);
  };

  return (
    
      <div className="divall">      
      

      <div>
        <div style={{ position: 'absolute', display: 'none', left: '-9999px' }}>
          <BonSort ref={componentRef} products={details}  selectbonnum={selectbonnum} selectservice={selectservice} dateselected={dateselected}/>
        </div>
      </div>
{showerror  && ( <Error
          mesag={mesagerror}
          setshowerror={setshowerror}
        />)}
      {showListProd && (
        <ListProduct
          handleAddProductToStock={null}
          typo={2}
          setAlertType={null}
          setAlertMessage={null}
          setAlertVisible={null}
          setshowlistprod={setshowlistprod}
          onAddProduct={handleSaveaddoneprod}
          productsbon={details}
        />
      )}
<div className='divparent '>
      <h2 className='titrawi fs-4'>معاينة وصولات الخروج</h2>

      <div className='filtra'>
<span className='fw-bold fs-5 '>المصلحة:</span>
      <Form.Group controlId="categorySelect" className='inpoto'>
            
            <Form.Control
              as="select"
              value={selectedservice}
              onChange={handelservicechange} 
            >
              <option value="">كل المصالح </option>
              {services.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.desig}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          </div >
 
      <Table striped bordered hover >
        <thead>
          <tr>
            <th> {order==='asc'?<FaArrowUp  onClick={()=>setorder('desc')} />:<FaArrowDown onClick={()=>setorder('asc')} />}        رقم الوصل   </th>
            <th>التاريخ</th>
            <th>المصلحة</th>            
          </tr>
        </thead>
        <tbody>
        {bonComs.length > 0 ? (
  bonComs.map((bonCom) => (
    <tr 
      className='taboo' 
      key={bonCom.id} 
      onClick={() => { setindextable(bonCom.id); handleRowClick(bonCom); }} 
      
      style={{ height: '50px' ,cursor: 'pointer', backgroundColor: selectedBonCom && selectedBonCom.id === bonCom.id ? 'green' : 'transparent' }}
    >
      <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>
        {bonCom.num}
      </td>
      <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>
        {bonCom.date}
      </td>
      <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>
        {bonCom.desig}
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="3" className="text-center text-muted">لا يوجد أي وصل خروج</td>
  </tr>
)}

        </tbody>
      </Table>


      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      </div>

      {bonComs.length > 0 && selectedBonCom ? (
  <div className='divchild' style={{ backgroundColor: '#e8f8f5', borderRadius: '20px', paddingTop: '10px' }}>
    <div className='text-center mb-3'>
      <div className='text-center border border-2 border-dark d-inline-block py-2 px-3 fs-4' style={{ borderRadius: '10px' }}>
        <span className='text-center'>تفصيل وصل الخروج رقم</span>
        <span className='bg-warning fw-bold d-inline m-3 py-1 px-3 rounded'>{selectedBonCom.num}</span>
      </div>
    </div>
    <div style={{ textAlign: 'center' }}>
      <Button
        className="btn btn-warning mr-2 yy fs-5"
        onClick={() => handlePrintClick(selectedBonCom)}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <FaPrint /> طباعة
      </Button>
      <Button
        className="btn btn-primary mr-2 yy fs-5"
        onClick={() => { setshowlistprod(true); setSelectedBonCom(selectedBonCom); }}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <IoAddCircle /> إضافة مواد
      </Button>
      <Button
        className="btn btn-danger yy fs-5"
        onClick={(e) => { setselectbonnum(selectbonnum); e.stopPropagation(); handleDeleteClick(selectedBonCom); }}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <MdDeleteForever /> حذف
      </Button>
    </div>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>المادة</th>
          <th>الكمية</th>
          <th>العملية</th>
        </tr>
      </thead>
      <tbody>
        {details.map((detail) => (
          <tr key={detail.iddet}>
            
            <td>{detail.desig_prod}</td>
            <td>{detail.quent}</td>
            <td>
              <Button
                className="btn btn-danger yy"
                onClick={(e) => { setselectprod(detail.desig_prod); handleDeleteClickdet(detail.iddet); }}
              >
                حذف
              </Button>
              <Button
                className="btn btn-info yy"
                onClick={() => handleModifyClick(detail)}
              >
                تعديل
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
) : null}

      

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف الوصل رقم {selectbonnum}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modify Modal */}
      <Modal show={showModifyModal} onHide={() => setShowModifyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل الكمية</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="modifiedQuantity">
              <Form.Label>الكمية الجديدة</Form.Label>
              <Form.Control
                type="text"
                value={modifiedQuantity}
                onChange={(e) => setModifiedQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModifyModal(false)}>
            إلغاء
          </Button>
          <Button variant="warning" onClick={confirmModify}>
            تعديل
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal Details */}
      <Modal show={showDeleteModaldet} onHide={() => setShowDeleteModaldet(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف المادة {selectprod}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModaldet(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDeletedet}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactToPrint
        trigger={() => <button id="print-btn" style={{ display: 'none' }}>Print</button>}
        content={() => componentRef.current}
      />
   
    </div>
    
  );
};

export default ListeDeBonDeSortie;
