import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { Modal, Button, Table, Form } from 'react-bootstrap';

const ServicesEtablissement = ({ etabId }) => {
  const [services, setServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showNewServiceModal, setShowNewServiceModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServicename, setSelectedServicename] = useState(0);
  const [newServiceName, setNewServiceName] = useState('');
  const [newServiceNamefr, setNewServiceNamefr] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [allusernotresp, setAllusernotresp] = useState([]);
  const [iduserselect, setiduserselect] = useState(null);
  

  useEffect(() => {
    fetchServices();
    fetchAllusernotresp();
  }, [etabId]);

  const fetchServices = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/etabservices`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services', error);
    }
  };

  const fetchAllServices = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/services', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAllServices(response.data);
    } catch (error) {
      console.error('Error fetching all services', error);
    }
  };


  const fetchAllusernotresp = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/user_list_not_resp', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAllusernotresp(response.data);
      if (response.data.length > 0) {
        setiduserselect(response.data[0].id);}
        else {
          setiduserselect(null);
        }

    } catch (error) {
      console.error('Error fetching all services', error);
    }
  };

  

  const handleAddService = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(`/service_etabs`, { id_service: id }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchServices();
      setShowModal(false);
    } catch (error) {
      console.error('Error adding service', error);
    }
  };
  
  const handleAddusertoService = async () => {
    if (!selectedService || !iduserselect) {
      alert("Please select a service and a user.");
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(
        `/serv_rep`,
        { id_service: selectedService, id_user: iduserselect },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      console.log("Service added successfully");
  
      fetchServices();
      setShowUserModal(false);
  
      setAllusernotresp(prevUsers => {
        // Ensure iduserselect is a number
        const idToRemove = Number(iduserselect);
        
        console.log("Previous users:", prevUsers);
        console.log("User to remove:", idToRemove);
  
        // Filter out the selected user
        const updatedUsers = prevUsers.filter(user => user.id !== idToRemove);
  
        console.log("Updated users after removal:", updatedUsers);
  
        // Set new user selection
        if (updatedUsers.length > 0) {
          setiduserselect(updatedUsers[0].id);
          console.log("Selected user set to:", updatedUsers[0].id);
        } else {
          setiduserselect(null);
          console.log("Selected user set to null");
        }
  
        return updatedUsers;
      });
  
    } catch (error) {
      console.error('Error adding service:', error);
      alert('An error occurred while adding the user to the service.');
    }
  };
  
  
  
  

  const handleDeleteService = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/service_etabs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchServices();
    } catch (error) {
      console.error('Error deleting service', error);
    }
  };
  

  const handleCreateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post('/users', {
        name: userName,
        email: userEmail,
        password: userPassword,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setShowUserModal(false);
    } catch (error) {
      console.error('Error creating user', error);
    }
  };

  const handleCreateNewService = async () => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post('/services', { desig: newServiceName,desig_fr:newServiceNamefr }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchAllServices();
      setShowNewServiceModal(false);
    } catch (error) {
      console.error('Error creating new service', error);
    }
  };

  return (
    <div>
      <h2> مصالح المؤسسة </h2>
      <Table striped bordered hover>
        <thead>
          <tr>
           
            <th>المصلحة</th>
            <th>رئيس المصلحة </th>
            <th>العملية</th>
          </tr>
        </thead>
        <tbody>
          {services.map(service => (
            <tr key={service.id}>
              
              
              <td>{service.desig}</td>
              <td>{service.resp_name===null ?(<Button variant="info" onClick={() => {setShowUserModal(true);setSelectedService(service.id_ser_et);setSelectedServicename(service.desig)}}>تعيين المسؤول  </Button>):service.resp_name}</td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteService(service.id_ser_et)}>حذف</Button>
                
                
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" onClick={() => { setShowModal(true); fetchAllServices(); }}>
        Add Service
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Service to Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={() => setShowNewServiceModal(true)}>New Service</Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Designation (FR)</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allServices.map(service => (
                <tr key={service.id}>
                  <td>{service.id}</td>
                  <td>{service.desig_fr}</td>
                  <td>{service.desig}</td>
                  <td>
                    <Button variant="success" onClick={() => handleAddService(service.id)}>
                      Select
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUserName">
              
              <Form.Label>Name</Form.Label>
              

              <Form.Control
                type="text"
                placeholder="Enter name"
                value={selectedServicename}
                onChange={(e) => setUserName(e.target.value)} readOnly
              />
            </Form.Group>
           
           
            <Form.Group>
              <Form.Label>المهنة </Form.Label>
              <Form.Control
              as="select"
              
              onChange={e => setiduserselect(e.target.value )}
            >
              
              {allusernotresp.map((aallusernotresp) => (
                <option key={aallusernotresp.id} value={aallusernotresp.id}>
                  {aallusernotresp.name}
                </option>
              ))}
            </Form.Control>
            </Form.Group>
             
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddusertoService}>
            Create User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewServiceModal} onHide={() => setShowNewServiceModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewServiceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service name"
                value={newServiceName}
                onChange={(e) => setNewServiceName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formNewServiceNamefr">
              <Form.Label>Service Namefr</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service namefr"
                value={newServiceNamefr}
                onChange={(e) => setNewServiceNamefr(e.target.value)}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewServiceModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateNewService}>
            Create Service
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServicesEtablissement;
