import React, { useState,useEffect } from 'react';
import   "./SideBar.css";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
}from "react-icons/fa";
import { NavLink,Outlet } from 'react-router-dom';


const Sidebar = () => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const [isSmallScreen, setIsSmallScreen] = useState(false);


    useEffect(() => {
        // Function to check the screen size
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Check the screen size on initial render
        handleResize();

        // Add event listener to update the screen size on resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const menuItem=[                         


        
        {
            path:"listhiba",
            name:" معاينة دخول مصادر اخرى ",
            icon:<FaTh/>
        },


        {
            path:"hiba",
            name:" دخول مصادر اخرى ",
            icon:<FaTh/>
        },
        {
            path:"stock2",
            name:"المخزون الحالي",
            icon:<FaTh/>
        },

        {
            path:"listbonsort",
            name:"   وصل  الخروج  ",
            icon:<FaTh/>
        },

        {
            path:"boncomattent",
            name:"   وصل الطلب في الانتضار ",
            icon:<FaTh/>
        },
        
        {
            path:"listbonliv",
            name:"   وصل الاستلام معاينة ",
            icon:<FaTh/>
        },

        {
            path:"bonliv",
            name:"   وصل الاستلام  ",
            icon:<FaTh/>
        },


        {
            path:"listboncom",
            name:" معاينة وصولات الطلب  ",
            icon:<FaTh/>
        },

        {
            path:"userlist",
            name:" المستخدمين  ",
            icon:<FaTh/>
        },

        {
            path:"services",
            name:" المصالح  ",
            icon:<FaTh/>
        },

        {
            path:"boncom",
            name:"إنشاء طلب  ",
            icon:<FaTh/>
        },

        {
            path:"stock",
            name:"مخزون المواد الاستهلاكية",
            icon:<FaTh/>
        },
        
        {
            path:"list_prod",
            name:"قائمة المواد الاستهلاكية  ",
            icon:<FaTh/>
        },

        {
            path:"list_prod2",
            name:"قائمة المواد 2  ",
            icon:<FaTh/>
        },


        {
            path:"login2",
            name:"دخول",
            icon:<FaTh/>
        },
        {
            path:"recette",
            name:"الإيرادات",
            icon:<FaTh/>
        },
        
        {
            path:"budjet",
            name:"الميزانية",
            icon:<FaRegChartBar/>
        },
        {
            path:"imprimer",
            name:"المطبوعات",
            icon:<FaCommentAlt/>
        },
        {
            path:"configuration",
            name:"الاعدادات",
            icon:<FaShoppingBag/>
        },
        {
            path:"operation",
            name:" العمليات",
            icon:<FaThList/>
        },
        {
            path:"compte",
            name:" حسابي",
            icon:<FaCommentAlt/>
        }
    ]
    return (
        <div className="container">
           <div style={{width: isOpen ? "200px" : "50px"}} className={isOpen ? "sidebar":"sidebar2" }  >
               <div className="top_section">
                   
                   <div style={{marginLeft: isOpen ? "50px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
                   <h1 style={{display: isOpen ? "block" : "none"}} className="logo">Logo</h1>
               </div>
               <div className={isOpen ? "buttom_section":"buttom_section2" } >
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active"  onClick={isSmallScreen ? toggle : null}>
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
               </div>
           </div>
           <main><Outlet /></main>
        </div>
    );
};

export default Sidebar;