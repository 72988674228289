import React, { useState, useEffect , useRef  } from 'react';
import axiosInstance from './axiosConfig';
import { Modal, Button, Form, Pagination,Table } from 'react-bootstrap';
import './stock.css';
import StockInit2 from './print/StockInit2';
import { formatCurrency } from './formatCurrency ';

import ReactToPrint from 'react-to-print';
import Listproduct from './ListProduct';
import Alert from './Alert';
import FichedeStock from './FichedeStock';
import { FaPrint } from "react-icons/fa";
const Stock2 = () => {

  
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const componentRef = useRef();
  const [products, setProducts] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [fiche, setfiche] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showfiche, setshowfiche] = useState(false);
  
  const [showlistprod, setshowlistprod] = useState(false);
  const [newProduct, setNewProduct] = useState({ name: '', stock: '', price: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [selectedname, setselectedname] = useState('');
  const [selectedstock, setselectedstock] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryname, setSelectedCategoryname] = useState('');
  const [printTrigger, setPrintTrigger] = useState(false);
  const [filters, setFilters] = useState({ name: '', category: '' });
  const [totalSum, setTotalSum] = useState(0);
  const [totalSum2, setTotalSum2] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchCategories();
    fetchProducts();
    
  }, []);

   const handleAlertClose = () => {
    setAlertVisible(false);
  };

  useEffect(() => {
    fetchProducts();
    
  }, [currentPage, selectedCategory,searchName]);


  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/stock', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          name: searchName,
          category: selectedCategory,
          page: currentPage,
          itemsPerPage // Send items per page to API
        }
      });
      const { data, total, totalSum } = response.data;
      setProducts(data || []);
      
      setTotalPages(response.data.last_page);
      setTotalSum(totalSum);
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]); 
    }
  };




  const fetchProducts2 = async () => {
    try {
      console.log("selectedCategory",selectedCategory);
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/stock3', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          
          category: selectedCategory,
          page: currentPage,
          itemsPerPage // Send items per page to API
        }
      });
      const { data, total, totalSum } = response.data;
      setProducts2(data || []);
      
      
      setTotalSum2(totalSum);
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts2([]); 
    }
  };





  const fiche_stock = async (id) => {
    try {
      
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No token found');
        }

        const response = await axiosInstance.get(`/fich_stock/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setfiche(response.data);
        console.log(response.data);
        setshowfiche(true);
    } catch (error) {
        console.error('Error fetching stock data:', error);
    }
};





  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/catprod', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategories(response.data || []); // Ensure categories is an array
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]); // Fallback to empty array on error
    }
  };

  const handleAddProductToStock = (product) => {
    setProducts((prevProducts) => [product, ...prevProducts]);
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleAddProduct = async () => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post('/products', newProduct, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchProducts(currentPage, filters);
      setShowModal(false);
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedCategoryname (e.target.options[e.target.selectedIndex].text);
    setCurrentPage(1);
  };


  const handleModifyProduct = (product) => {
    // Implement modify product logic
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchProducts(currentPage, filters);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrintClick = (bonCom) => {
    // if (selectedBonCom && selectedBonCom.id === bonCom.id) {
      // If the clicked row is already selected, just trigger print
      setPrintTrigger(true);
    // } else {
    //   // Otherwise, fetch details and then trigger print
    //   fetchDetails(bonCom.id);
    //   setSelectedBonCom(bonCom);
    //   setPrintTrigger(true);
    // }
  };

  const handlePrint = () => {
    const table = document.querySelector('.print-table').innerHTML;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body { margin: 0; }
              .no-print { display: none; }
            }
              .table{
              border:2px solid red;
              direction:rtl;
              width:90%;
              }
          </style>
        </head>
        <body>
          <header>
            <h1>Header Content</h1>
          </header>
          
          <footer>
            <p>Footer Content</p>
          </footer>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>

      <h1 className='titrawi'>المخزون الحالي للمواد</h1>
      <div className="rowa"> 
      <div className="">
        <Form>
        <div className='divofiltr'>
          <Form.Group controlId="filterName"  className='inputa'>
          
            <Form.Control
              type="text"
              placeholder="  ابحث باسم المادة"
              name="name"
              value={searchName}
              onChange={handleSearchNameChange}
              
            />
          </Form.Group>
          <Form.Group controlId="filterCategory"  className='inputa yy'>
            
            <Form.Control
              as="select"
              name="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
             
            >
              <option value="">كل الأصناف </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.desig}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          

      

          </div>
        </Form>
       
        
      </div>

      <div className=' '>            
            <ReactToPrint
                trigger={() => <button onClick={()=>alert("jj")}  variant="primary" className="btn btn-warning yy fw-bold fs-5">طباعة   <FaPrint /> </button>}
                content={() => componentRef.current}
            />
             <div style={{ position: 'absolute',display:'none', left: '-9999px' }}>
                <StockInit2 ref={componentRef}  products2={products2} selectedCategory={selectedCategory}  selectedCategoryname={selectedCategoryname}  />
            </div>
        </div>
        </div>
      
       <div className=" "> 
        
        <Table striped bordered hover >
          <thead>
            <tr>
              <th>المادة</th>
              
              <th>المخزون الحالي</th>
              <th>السعر الحالي</th>
              <th>المجموع</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(products) && products.map((product) => (
              <tr key={product.id}>
                <td>{product.desig_prod}</td>                
                <td>{product.quent}</td>
                <td>{formatCurrency(product.prix)}</td>
                <td>{formatCurrency(product.quent * product.prix)}</td>
                <td><Button className="btn btn-info yy"  onClick={()=>{setselectedstock(product.quent);setselectedname(product.desig_prod);fiche_stock(product.id)}}>بطاقة المخزون</Button></td>
              </tr>
            ))}
          </tbody>
          </Table>
        {/* </table> */}
      </div>

      <div className='sommme'>
      <div>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      </div>

<div className='somme'><span>المجموع الكلي: </span>
<span>{formatCurrency(totalSum)}</span>
  
  </div>
</div>

{ showfiche && <FichedeStock fiche={fiche} selectedname={selectedname} selectedstock={selectedstock} setshowfiche={setshowfiche}/>  }



      <Alert message={alertMessage} visible={alertVisible} type={alertType} onClose={handleAlertClose} />
      {showlistprod && <Listproduct typo={0}  setAlertType={setAlertType} setAlertMessage={setAlertMessage}  setAlertVisible={setAlertVisible} setshowlistprod={setshowlistprod} onAddProduct={handleAddProductToStock} />}
      
      

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={newProduct.name} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stock</Form.Label>
              <Form.Control type="number" name="stock" value={newProduct.stock} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" name="price" value={newProduct.price} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleAddProduct}>Add Product</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Stock2;
