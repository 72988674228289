import React from 'react';


import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './compenent/sideBar';
import Operation from './compenent/Operation';
import Compte from './compenent/Compte';

import Configuration from './compenent/Configuration';


import Login2 from './compenent/Login2';






import Product from './compenent/Product';
import Stock from './compenent/Stock';
import Stock2 from './compenent/Stock2';
import ListProduct from './compenent/ListProduct';
import ListProduct2 from './compenent/Listproduct2';
import BonCommande from './compenent/BonCommande';
import ServicesEtablissement from './compenent/ServicesEtablissement';
import UserList from './compenent/UserList';
import ListBonCom from './compenent/ListBonCom';
import BonDeLivraison from './compenent/BonDeLivraison';
import ListeBonDeLivraison from './compenent/ListeBonDeLivraison';
import BonDeCommandesEnAttent from './compenent/BonDeCommandesEnAttent';
import ListeDeBonDeSortie from './compenent/ListeDeBonDeSortie';
import ListProductinv from './compenent/ListProductinv';
import Hiba from './compenent/Hiba';
import Listhiba from './compenent/Listhiba';


const App = () => {
  return (
    <BrowserRouter>
     
        <Routes>
        
        <Route exact path="/" element={<Login2 />} />
        
        

        <Route  exact path="app" element={<Sidebar />} >
        <Route path="login2" element={<Login2 />} />
        <Route path="boncom" element={<BonCommande />} />
           
           <Route path="stock" element={<Stock />} />
           <Route path="stock2" element={<Stock2 />} />
           <Route path="list_prod" element={<ListProduct />} />
           <Route path="list_prod2" element={<ListProduct2 />} />
           <Route path="services" element={<ServicesEtablissement />} />
           <Route path="listbonsort" element={<ListeDeBonDeSortie />} />
           <Route path="listhiba" element={<Listhiba />} />
           
           
           <Route path="userlist" element={<UserList />} />
           <Route path="listboncom" element={<ListBonCom />} />
           <Route path="bonliv" element={<BonDeLivraison />} />
           <Route path="listbonliv" element={<ListeBonDeLivraison />} />
           <Route path="boncomattent" element={<BonDeCommandesEnAttent />} />
           <Route path="listprodinv" element={<ListProductinv />} />
           <Route path="hiba" element={<Hiba />} />
           
           
           
           
           
          
          
          
          <Route path="operation" element={<Operation />} />
          <Route path="compte" element={<Compte />} />  
          <Route path="product" element={<Product />} />
          <Route path="configuration" element={<Configuration />} />
        </Route>
          
        </Routes>
       
    </BrowserRouter>
  );
};

export default App;