// Stock_init.jsx

import './stock_init.css';
import React, { useState, useEffect , useRef  } from 'react';
import { formatCurrency } from '../formatCurrency ';
import axiosInstance from '../axiosConfig';

const StockInit = React.forwardRef(({selectedCategory,selectedCategoryname}, ref) => {
    
    const [datetoday, setdatetoday] = useState(null);
    const [TotalSum2, setTotalSum2] = useState(null);
    const [Products2, setProducts2] = useState([]);
    
    const fetchProducts2 = async () => {
        try {          
            setProducts2([]);
            console.log("ggggggggg");
          const token = localStorage.getItem('token');
          const response = await axiosInstance.get('/stock3', {
            headers: { Authorization: `Bearer ${token}` },
            params: {              
              category: selectedCategory,                            
            }
          });
          const { data, total, totalSum } = response.data;
          setProducts2(data || []);
          
          
          setTotalSum2(totalSum);
        } catch (error) {
          console.error('Error fetching products:', error);
          setProducts2([]); 
        }
      };

    useEffect(() => {
        const now = new Date();
const currentDate = now.toLocaleDateString();
setdatetoday(currentDate);
    
        fetchProducts2();
    
      }, [selectedCategory]);


    return (
        <div ref={ref} className="stokodiv">            
        
            <p className='fs-4  mb-0'>مديرية التربية لولاية: بشــار</p>
            <p className='fs-4  mb-3 '>متوسطة: بوعنيني العماري</p>
            <div className='text-center'>

            <h1 className='text-center  fs-1  border border-2 border-dark d-inline px-3 rounded'>المخزون الحالي</h1>

            </div>


            <div className='container justify-content-between  mb-0 pb-0'>


            <div className='col '>
            <span className='fs-5'>التاريخ:</span>
            <span className='fw-bold fs-5'> {datetoday}</span>
             </div>

             <div className='col  text-center fs-3 fw-bold'>
             {selectedCategory===""?null:selectedCategoryname}
             </div>
             

            <div className='col text-start '>
            <span className='fs-5'>المبلغ الإجمالي:</span>
            <span className='fw-bold fs-5'> {formatCurrency(TotalSum2)}</span>
               

             </div>


            </div>


            
            <div className="print-table">
                <table className="table mt-3 mt-0">
                    <thead>
                        <tr className='bg-info fs-4'>
                            <th>المادة</th>
                            <th>المخزون الحالي</th>
                            <th>السعر الحالي</th>
                            <th>المجموع</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(Products2) && Products2.map((product) => (
                            <tr key={product.id}>
                                <td className='text-end px-1'>{product.desig_prod}</td>
                                <td>{product.quent}</td>
                                <td className='text-end px-1'>{formatCurrency(product.prix)}</td>
                                <td className='text-end px-1'>{formatCurrency(product.quent * product.prix)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default StockInit;

