import React,{Component} from "react";
class Configuration extends Component{
    render(){
        return(
            <div>
                Configuration
            </div>
        )
    }
}
export default Configuration;