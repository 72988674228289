import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { Form, Table, Button, Pagination, Modal } from 'react-bootstrap';
import './listprod.css';
import { IoClose } from "react-icons/io5";


const Fornis = ({fornisbon,setShowFournisseurList,setnamefornis,setidfornis }) => {
  


  const [fornis, setfornis] = useState([]);
  
  const [searchName, setSearchName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  
  const [selectedProduct, setSelectedProduct] = useState(null);
 
  const [newname, setnewname] = useState('');
  const [newnum, setnewnum] = useState('');
  const [newrib, setnewrib] = useState('');
  const [showDeleteModaldet, setShowDeleteModaldet] = useState(false);
  const [bonComToDeletedet, setBonComToDeletedet] = useState(null);
  const [selectedBonCom, setSelectedBonCom] = useState(null);
  
  const [showeditmodal, setshoweditmodal] = useState(false);

  const [nametoedit, setnametoedit] = useState(null);
  const [numtoedit, setnumtoedit] = useState(null);
  const [ribtoedit, setribtoedit] = useState(null);
  const [idtoedit, setidtoedit] = useState(null);



 
  

  useEffect(() => {
    
    fetchfornis();
  }, []);

  useEffect(() => {
    fetchfornis();
  }, [searchName, selectedCategory, currentPage]);

  const fetchfornis = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/fornis', {
        params: {
          name: searchName,
          
          page: currentPage
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data.data);
      setfornis(response.data.data);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Error fetching fornis:', error);
    }
  };


  const fetchfornisdet = async (idd) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/fornisdet/${idd}`, {
        
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data.data);
      
      setidtoedit(response.data.id);
      setnametoedit(response.data.name);
      setnumtoedit(response.data.num);
      setribtoedit(response.data.rib);
      setshoweditmodal(true);
      
    } catch (error) {
      console.error('Error fetching fornis:', error);
    }
  };



  const confirmModify = () => {
    const token = localStorage.getItem('token');
    axiosInstance.put(`/fornis/${idtoedit}`, { name: nametoedit,num:numtoedit,rib:ribtoedit }, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const updatedDetails = fornis.map(det =>
          det.id === idtoedit ? { ...det, name: nametoedit } : det
        );
        setfornis(updatedDetails);
        setshoweditmodal(false);
      })
      .catch(error => {
        console.error('There was an error updating the bon de commande detail!', error);
      });
  };




  
  const confirmDeletedet = () => {
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/fornis/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setfornis(fornis.filter(bc => bc.id !== bonComToDeletedet));
        setShowDeleteModaldet(false);
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
      });
  };



  const handleDeleteClickdet = (bonCom) => {
    setSelectedBonCom(bonCom.name); // Show details before delete
    setBonComToDeletedet(bonCom.id);
    setShowDeleteModaldet(true);
  };


  const addfornis = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.post('/add_fornis',  
         {
          name: newname,
          num:newnum,
          rib:newrib                    
        },
        {
        headers: {
          Authorization: `Bearer ${token}`
        
      }});
      console.log(response.data);
       handleAddfornis(response.data)
    } catch (error) {
      console.error('Error fetching fornis:', error);
    }
  };
 
  const handleAddfornis = (forniso) => {
    setfornis((fornis) => [forniso, ...fornis]);
  };
  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(1);
  };

  

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 

  const handleSelectStockClick = (product) => {
    setnamefornis(product.name)
    setidfornis(product.id)
    setShowFournisseurList(false)
  };


  



  
   



  return (
    <div className='listprod'>
      
    
       <div className='titrop'>
       <h2> قائمة الموردين</h2>
      <IoClose  onClick={() => setShowFournisseurList(false)}  className='clossse' />
     
      </div>
      <div className="    ">
        <Form>
        <div className='divofiltr'>
          <Form.Group controlId="searchName" className='inpoto'>
            
            <Form.Control
              type="text"
              placeholder="البحث باسم المادة"
              value={searchName}
              onChange={handleSearchNameChange}
              className='inpo'
            />
          </Form.Group>
          <Button
              onClick={() => setShowModal(true)}
              className='btn btn-success'>
             إضافة مورد
            </Button> 
          </div>
        </Form>

       


      </div>
      <Table striped bordered hover className='taboo'>
        <thead>
          <tr>
            <th>المورد</th>
            
            <th></th>
          </tr>
        </thead>
        <tbody>
    {fornis.map((forniso) => (
      <tr key={forniso.id}>
        <td>{forniso.name}</td>
        
        <td>
          
            <Button
              onClick={() => handleSelectStockClick(forniso)}
              className='btn btn-dghh'>
              تحديد 
            </Button>
            <Button
              onClick={() => fetchfornisdet(forniso.id)}
              className='btn btn-success'>
              تغيير 
            </Button>
            <Button
              onClick={() => handleDeleteClickdet(forniso)}
              className='btn btn-danger'>
              حذف 
            </Button>
         

 
        </td>
      </tr>
    ))}
  </tbody>
      </Table>
      {totalPages > 1 &&
       (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}



       {/* Delete Modal Details */}
       <Modal show={showDeleteModaldet} onHide={() => setShowDeleteModaldet(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف المورد ؟
          {selectedBonCom}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModaldet(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDeletedet}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.exis === 0 ? 'Select Stock' : 'Show Stock'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newname">
              <Form.Label>المورد </Form.Label>
              <Form.Control type="text" value={newname}  onChange={(e) => setnewname(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="nenum">
              <Form.Label>رقم التعريف الجبائي </Form.Label>
              <Form.Control type="text" value={newnum}  onChange={(e) => setnewnum(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="newrib">
              <Form.Label>رقم التعريف البنكي </Form.Label>
              <Form.Control type="text" value={newrib} onChange={(e) => setnewrib(e.target.value)} />
            </Form.Group>                                  

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => addfornis()} >
            Save
          </Button>
        </Modal.Footer>
      </Modal>




      

      
      <Modal show={showeditmodal} onHide={() => setshoweditmodal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.exis === 0 ? 'Select Stock' : 'Show Stock'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newname">
              <Form.Label>المورد </Form.Label>
              <Form.Control type="text" value={nametoedit}  onChange={(e) => setnametoedit(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="nenum">
              <Form.Label>رقم التعريف الجبائي </Form.Label>
              <Form.Control type="text" value={numtoedit}  onChange={(e) => setnumtoedit(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="newrib">
              <Form.Label>رقم التعريف البنكي </Form.Label>
              <Form.Control type="text" value={ribtoedit} onChange={(e) => setribtoedit(e.target.value)} />
            </Form.Group>                                  

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshoweditmodal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => confirmModify()} >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

 

    </div>
  );
};

export default Fornis;
