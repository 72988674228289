import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './axiosConfig';
import { Table, Button, Modal, Form,Pagination } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import ListProduct from './ListProduct';
import BonLiv from './print/bonLiv';
import ListProductinv from './ListProductinv';
import Fornis from './Fornis';

import { FaPrint } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import { FaArrowUp,FaArrowDown } from "react-icons/fa";
import Error from './Error';

import { FaUserCheck } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa";
import { FaPenAlt } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { MdMiscellaneousServices } from "react-icons/md";

import { FaUsers } from "react-icons/fa6";
import { formatCurrency } from './formatCurrency ';


const ListeBonDeLivraison = () => {
  const [bonComs, setBonComs] = useState([]);
  const [selectedBonCom, setSelectedBonCom] = useState(null);
  const [details, setDetails] = useState([]);
  const [detailscons, setDetailscons] = useState([]);
  const [detailsinv, setDetailsinv] = useState([]);
  
  const [prixx, setprixx] = useState('');
  const [desigg, setdesigg] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModaldet, setShowDeleteModaldet] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [bonComToDelete, setBonComToDelete] = useState(null);
  const [bonComToDeletedet, setBonComToDeletedet] = useState(null);
  const [BonComToDeletetyp, setBonComToDeletetyp] = useState(null);
  
  const [dateselected, setdateselected] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [modifiedQuantity, setModifiedQuantity] = useState('');
  const [modifieddesig, setModifieddesig] = useState('');
  const [modifiedprix, setModifiedprix] = useState('');
  const [selectprod, setselectprod] = useState(null);
  const [selectbonnum, setselectbonnum] = useState(null);
  const [selectbonforns, setselectbonforns] = useState(null);
  const [selectbonsumm, setselectbonsumm] = useState(null);
  const [selectbonid, setselectbonid] = useState(null);
  const [showListProd, setshowlistprod] = useState(false);
  const [showListProdinv, setshowlistprodinv] = useState(false);
  const [printTrigger, setPrintTrigger] = useState(false);
  const [showFournisseurList, setShowFournisseurList] = useState(false);
  const [showmodalserv, setshowmodalserv] = useState(false);
  
  const [showpaneladd, setshowpaneladd] = useState(false);
  const [mesagerror, setmesagerror] = useState();
  const [showerror, setshowerror] = useState(false);
  
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [indextable, setindextable] = useState('asc');
  
  const [namefornis, setnamefornis] = useState('');
  const [idfornis, setidfornis] = useState(null);
  const [selectedindex, setselectedindex] = useState(null);
  const [selectedtyp, setselectedtyp] = useState(null);
  
  const [order, setorder] = useState('desc');

  const componentRef = useRef();

  const handleAddProductTodet = (product) => {
    setDetails((details) => [ ...details,product]);
    if (product.typ==="1")
    {setDetailscons((detailscons) => [product, ...detailscons]);}
    else 
    
    if (product.typ==="2")
      {setDetailsinv((detailsinv) => [product, ...detailsinv]);}
    console.log("detailscons",detailscons,product.typ);    
  };

  const handleSaveaddoneprod = (id_prod, quent, desig_prod,prix) => {
    
    const token = localStorage.getItem('token');
    axiosInstance.post('/add_one_prod_liv', { id_prod, quent, id_bon: selectbonid,prix }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        
               
        handleAddProductTodet({ id: id_prod, quent, desig:desig_prod,prix,typ:"1" ,iddet:response.data.id,nl:response.data.nl});
      })
      .catch(error => {
        console.error('There was an error adding the product!', error);
      });
  };

  
  const handleSaveaddoneprodinv = (id_prod, quent, desig_prod,prix) => {
    
    const token = localStorage.getItem('token');
    axiosInstance.post('/add_one_prod_liv_inv', { id_prod, quent, id_bon: selectbonid,prix }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        
        handleAddProductTodet({ id: id_prod, quent, desig:desig_prod,prix,typ:"2",iddet:response.data.id,nl:response.data.nl });
      })
      .catch(error => {
        console.error('There was an error adding the product!', error);
      });
  };

// Calculate the total sum of quent * prix in the details array
useEffect(() => {
  if (details.length !== 0) {  // Ensure details array is not empty
    const totalSum = details.reduce((sum, item) => {
      return sum + item.quent * item.prix;
    }, 0);

    const updatedBonComs = bonComs.map(item => {
      if (item.num === selectbonnum) {  // Ensure you have a valid selectbonnum
        return { ...item, somme: totalSum };
      }
      return item;
    });

    setBonComs(updatedBonComs);
  }
}, [details]);  // Add selectbonnum as a dependency

// Call updateBonComs where necessary, for example, after updating details



  const handleSaveaddoneprodserv = (id_prod, quent, desig_prod,prix) => {
    console.log("det serv",{ desig:desigg, id_bon: selectbonid,prix:prixx });
    const token = localStorage.getItem('token');
    axiosInstance.post('/add_one_prod_liv_serv', { desig:desigg, id_bon: selectbonid,prix:prixx }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log("dsdsd",response.data);
        
        handleAddProductTodet({ id: "1", quent:1, desig:desigg,prix:prixx ,typ:"3",iddet:response.data.id,nl:response.data.nl});
        setshowmodalserv(false);
        setdesigg(null);
        setprixx(null);
      })
      .catch(error => {
        console.error('There was an error adding the product!', error);
      });
  };

  

  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/bon_liv', {  params: {
        id_fornis: idfornis, page: currentPage,order
        
      }, headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setBonComs(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedBonCom(response.data.data[0]);
          fetchDetails(response.data.data[0].id);
          setselectbonid(response.data.data[0].id)  
          setdateselected(response.data.data[0].date)
          setselectbonnum(response.data.data[0].num)
          setselectbonforns(response.data.data[0].name);
          setselectbonsumm(response.data.data[0].somme);
          setindextable(response.data.data[0].id);
          
        setSelectedBonCom(response.data.data[0]);
        console.log(response.data.data);
        setTotalPages(response.data.last_page);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the bon de commande!', error);
      });
  }, [idfornis,currentPage,order]);

  const fetchDetails = (bonComId) => {
    setDetails([]);
    const token = localStorage.getItem('token');
    axiosInstance.get(`/bon_liv_det/${bonComId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setDetails(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the details!', error);
      });
  };

  const handleRowClick = (bonCom) => {
    if(selectbonid===bonCom.id){
      
    }
    else{
      setindextable(bonCom.id);
      setselectbonid(bonCom.id)  
      setdateselected(bonCom.date)
      setselectbonnum(bonCom.num)
      setselectbonforns(bonCom.name);
      setselectbonsumm(bonCom.somme);      
    setSelectedBonCom(bonCom);
    fetchDetails(bonCom.id);
    }
  };

  const handleDeleteClick = (bonCom) => {
    setSelectedBonCom(bonCom); // Show details before delete
    setBonComToDelete(bonCom);
    setShowDeleteModal(true);
  };

  const handleDeleteClickdet = (bonCom,typ) => {
    
    setBonComToDeletedet(bonCom);
    setBonComToDeletetyp(typ);
    setShowDeleteModaldet(true);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const addprodcons = () => {    
    setDetailscons(details.filter(bc => bc.typ === '1'));
    console.log("dettttttailll",detailscons);
    setshowlistprod(true);
    setshowpaneladd(false);    
  };

  const addprodinv = () => {    
    setDetailsinv(details.filter(bc => bc.typ === '2'));
    console.log("dettttttailll",detailscons);
    setshowlistprodinv(true);
    setshowpaneladd(false);    
  };

  const addprodserv = () => {        
    console.log("dettttttailll",detailscons);
    setshowmodalserv(true);
    setshowpaneladd(false);    
  };

  

  const confirmDelete = () => {
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/bonliv/${bonComToDelete.id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        const updatedBonComs = bonComs.filter(bc => bc.id !== bonComToDelete.id);
console.log("Filtered bonComs", updatedBonComs);
setBonComs(updatedBonComs);
console.log("updatedBonComs",updatedBonComs);

      
        setShowDeleteModal(false);
        if (bonComs.length > 0) {
          
              setindextable(updatedBonComs[0].id);
             setselectbonid(updatedBonComs[0].id)  ;
             setdateselected(updatedBonComs[0].date);
             setselectbonnum(updatedBonComs[0].num);
             setselectbonforns(updatedBonComs[0].name);
             setselectbonsumm(updatedBonComs[0].somme);      
           setSelectedBonCom(updatedBonComs[0]);
           fetchDetails(updatedBonComs[0].id);                          
             }  
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
      });
  };

  const confirmDeletedet = () => {
    const token = localStorage.getItem('token');

    if (BonComToDeletetyp==="1")
    {
      axiosInstance.delete(`/delete_one_cons/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setDetails(details.filter((_, idx) => idx !== selectedindex));
        setShowDeleteModaldet(false);        
         
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
        setmesagerror(error.response.data);
        setshowerror(true);
      });
    }
    else 

    if (BonComToDeletetyp==="2")
      {
        axiosInstance.delete(`/delete_one_inv/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          setDetails(details.filter((_, idx) => idx !== selectedindex));
          setShowDeleteModaldet(false);
          
          
        })
        .catch(error => {
          console.error('There was an error deleting the bon de commande!', error);
          setmesagerror(error.response.data);
          setshowerror(true);
        });
      }

      else

      if (BonComToDeletetyp==="3")
        {
          axiosInstance.delete(`/delete_one_serv/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
          .then(() => {
            setDetails(details.filter((_, idx) => idx !== selectedindex));
            setShowDeleteModaldet(false);
            

            
          })
          .catch(error => {
            console.error('There was an error deleting the bon de commande!', error);
            setmesagerror(error.response.data);
            setshowerror(true);
          });
        }
  
       
  };

  const handleModifyClick = (detail) => {
    setSelectedDetail(detail);
    setModifiedQuantity(detail.quent);
    setModifieddesig(detail.desig);
    setModifiedprix(detail.prix);
    setselectedtyp(detail.typ);
    setShowModifyModal(true);
  };

  const confirmModify = () => {
    const token = localStorage.getItem('token');

    if (selectedtyp==="1")
    {axiosInstance.put(`/edit_cons/${selectedDetail.iddet}`, { quent: modifiedQuantity,prix:modifiedprix }, { headers: { Authorization: `Bearer ${token}` } })
    .then(response => {
      const updatedDetails = details.map(det =>
        det.iddet === selectedDetail.iddet ? { ...det, quent: modifiedQuantity,prix:modifiedprix } : det
      );
      setDetails(updatedDetails);
      setShowModifyModal(false);
    })
    .catch(error => {
      console.error('There was an error updating the bon de commande detail!', error);
    });}
    else 

    if (selectedtyp==="2")
      {axiosInstance.put(`/edit_inv/${selectedDetail.iddet}`, { quent: modifiedQuantity,prix:modifiedprix }, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const updatedDetails = details.map(det =>
          det.iddet === selectedDetail.iddet ? { ...det, quent: modifiedQuantity,prix:modifiedprix } : det
        );
        setDetails(updatedDetails);
        setShowModifyModal(false);
      })
      .catch(error => {
        console.error('There was an error updating the bon de commande detail!', error);
      });}

      else
      if (selectedtyp==="3")
        {axiosInstance.put(`/edit_serv/${selectedDetail.iddet}`, { desig: modifieddesig,prix:modifiedprix }, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          const updatedDetails = details.map(det =>
            det.iddet === selectedDetail.iddet ? { ...det, desig: modifieddesig,prix:modifiedprix } : det
          );
          setDetails(updatedDetails);
          setShowModifyModal(false);
        })
        .catch(error => {
          console.error('There was an error updating the bon de commande detail!', error);
        });}
    
    
  };

  const handlePrintClick = (bonCom) => {
    if (selectedBonCom && selectedBonCom.id === bonCom.id) {
      // If the clicked row is already selected, just trigger print
      setPrintTrigger(true);
    } else {
      // Otherwise, fetch details and then trigger print
      fetchDetails(bonCom.id);
      setSelectedBonCom(bonCom);
      setPrintTrigger(true);
    }
  };

  useEffect(() => {
    if (printTrigger) {
      setPrintTrigger(false);
      document.getElementById('print-btn').click();
    }
  }, [printTrigger, details]);

  return (
    <div className="divall">

      <div>
        <div style={{ position: 'absolute', display: 'none', left: '-9999px' }}>
          <BonLiv ref={componentRef} products={details}  selectbonnum={selectbonnum}  dateselected={dateselected} selectbonforns={selectbonforns}   selectbonsumm={selectbonsumm}/>
        </div>
      </div>

      {showFournisseurList && (
        <Fornis  setnamefornis={setnamefornis} setidfornis={setidfornis} setShowFournisseurList={setShowFournisseurList} />
          
      )}

      {showListProd && (
        <ListProduct
          handleAddProductToStock={null}
          typo={4}
          setAlertType={null}
          setAlertMessage={null}
          setAlertVisible={null}
          setshowlistprod={setshowlistprod}
          onAddProduct={handleSaveaddoneprod}
          productsbon={detailscons}
        />
      )}

{showerror  && ( <Error
          mesag={mesagerror}
          setshowerror={setshowerror}
        />)}



{showListProdinv && (
        <ListProductinv
          handleAddProductToStock={null}
          typo={4}
          setAlertType={null}
          setAlertMessage={null}
          setAlertVisible={null}
          setshowlistprod={setshowlistprodinv}
          onAddProduct={handleSaveaddoneprodinv}
          productsbon={detailsinv}
        />
      )}

    
<div className='divparent '>
      <h2 className='titrawi fs-4'>معاينة وصولات الدخول</h2>
      <div className='d-flex ' style={{flexWrap:"wrap"}}>
      <Button className="btn btn-info mx-1"  onClick={()=>setidfornis(null)} > <FaUsers />   كل الموردين</Button>
    <Button className="btn btn-dark mx-1"  onClick={()=>setShowFournisseurList(true)}>  <FaUserCheck />   إختيار المورد</Button>
    
    {idfornis ?<div className='mt-2'> <span className='fw-bold fs-5'>وصولات</span> <span style={{borderRadius:"5px 20px"}} className='fw-bold fs-5 px-2 border border-primary border-3 text-primary'>{namefornis}</span> </div>:<span className='fw-bold fs-5'>كل الوصولات</span>}
    </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
            {order==='asc'?<FaArrowUp  onClick={()=>setorder('desc')} />:<FaArrowDown onClick={()=>setorder('asc')} />} 
            رقم الوصل

             </th>
            <th>التاريخ</th>
            <th>المورد</th>
            <th>العملية</th>
          </tr>
        </thead>
        <tbody>
          {bonComs.map((bonCom) => (
            <tr key={bonCom.id} onClick={() =>{handleRowClick(bonCom)}} style={{height: '50px', padding: '10px !important',cursor: 'pointer', backgroundColor: selectedBonCom && selectedBonCom.id === bonCom.id ? 'green' : 'transparent' }} >
              <td  className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>{bonCom.num}</td>
              <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>{bonCom.date}</td>
              <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>{bonCom.name}</td>
              <td className={indextable === bonCom.id ? 'fw-bold fs-5 text-success' : ''}>{formatCurrency(bonCom.somme)}</td>
              
            </tr>
          ))}
        </tbody>
      </Table>
 

      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
       </div>

      {selectedBonCom && (
        <div className='divchild' >
           <div className='text-center mb-3'>
      <div className='text-center border border-2 border-dark d-inline-block py-2 px-3 fs-4' style={{ borderRadius: '10px' }}>
        <span className='text-center'>تفصيل وصل الدخول رقم</span>
        <span className='bg-warning fw-bold d-inline m-3 py-1 px-3 rounded'>{selectedBonCom.num}</span>
      </div>
    </div>
        

          <div style={{ textAlign: 'center' }}>
      <Button
        className="btn btn-warning mr-2 yy fs-5"
        onClick={() => handlePrintClick(selectedBonCom)}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <FaPrint /> طباعة
      </Button>
      <Button
        className="btn btn-primary mr-2 yy fs-5"
        onClick={() => { setshowpaneladd(true); setSelectedBonCom(selectedBonCom); }}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <IoAddCircle /> إضافة مواد
      </Button>
      <Button
        className="btn btn-danger yy fs-5"
        onClick={(e) => { setselectbonnum(selectbonnum); e.stopPropagation(); handleDeleteClick(selectedBonCom); }}
        hidden={selectedBonCom.id !== selectbonid}
      >
        <MdDeleteForever /> حذف
      </Button>
    </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>المادة</th>
                <th>الكمية </th>
                <th>السعر </th>
                <th>المبلغ </th>
                <th>العملية</th>
              </tr>
            </thead>
            <tbody>
            {details.map((detail,index) => (
    <tr key={`${detail.iddet}-${detail.typ}`}>
        
        <td>{detail.desig}</td>
        <td>{detail.quent}</td>
        <td>{formatCurrency(detail.prix)}</td>
        <td>{formatCurrency(detail.prix * detail.quent)}</td>
        <td>
            <Button className="btn btn-danger" onClick={(e) => { setselectprod(detail.desig); setselectedindex(index); handleDeleteClickdet(detail.iddet, detail.typ); }}>حذف</Button>
            <Button className="btn btn-warning" onClick={() => handleModifyClick(detail)}>تعديل</Button>
        </td>
    </tr>
))}


            </tbody>
          </Table>
        </div>
      )}



      
<Modal show={showmodalserv} onHide={() => setshowmodalserv(false)}>
        <Modal.Header closeButton>
          <Modal.Title> تعيين الخدمة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <Form.Group controlId="productName">
              <Form.Label> موضوع الخدمة</Form.Label>
              <Form.Control type="text" 
              
              value={desigg}
                min="1" // Set the minimum value to 1
                onChange={(e) => setdesigg(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>المبلــغ</Form.Label>
              <Form.Control
                type="number"
                value={prixx}
                min="1" // Set the minimum value to 1
                onChange={(e) => setprixx(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowmodalserv(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveaddoneprodserv}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>








<Modal show={showpaneladd} onHide={() => setshowpaneladd(false)}>
        <Modal.Header closeButton >
          <Modal.Title> طبيعة الإضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
        <Button variant="info" className="mb-3 mx-1" onClick={() => addprodcons()}>
        <FaPenAlt className='mx-1' />  
            مواد إستهلاكية
          </Button>
          <Button variant="secondary" className="mb-3" onClick={addprodinv}>
          <FaLaptop className='mx-1' />  
     
            أجهزة وأثاث
          </Button>

          <Button variant="primary" className="mb-3  mx-1" onClick={addprodserv}>
          <MdMiscellaneousServices className='mx-1' />
      
            خدمات و إصلاحات
          </Button>

        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>



      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف الوصل رقم {selectbonnum}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modify Modal */}
      <Modal show={showModifyModal} onHide={() => setShowModifyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          
          { selectedtyp==="3"?
          <Form.Group controlId="modifiedQuantity">
              <Form.Label> المادة</Form.Label>
              <Form.Control
                type="text"
                value={modifieddesig}
                onChange={(e) => setModifieddesig(e.target.value)}
              />
            </Form.Group>
            :null}
   { selectedtyp!=="3"?
            <Form.Group controlId="modifiedQuantity">
              <Form.Label>الكمية الجديدة</Form.Label>
              <Form.Control
                type="text"
                value={modifiedQuantity}
                onChange={(e) => setModifiedQuantity(e.target.value)}
              />
            </Form.Group>
            :null}
            <Form.Group controlId="modifiedQuantity">
              <Form.Label>السعر الجديدة</Form.Label>
              <Form.Control
                type="text"
                value={modifiedprix}
                onChange={(e) => setModifiedprix(e.target.value)}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModifyModal(false)}>
            إلغاء
          </Button>
          <Button variant="warning" onClick={confirmModify}>
            تعديل
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal Details */}
      <Modal show={showDeleteModaldet} onHide={() => setShowDeleteModaldet(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف المادة {selectprod}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModaldet(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDeletedet}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactToPrint
        trigger={() => <button id="print-btn" style={{ display: 'none' }}>Print</button>}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default ListeBonDeLivraison;
