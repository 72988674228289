// Stock_init.jsx
import React from "react";
import './stock_init.css';

const StockInit = React.forwardRef(({ products }, ref) => {
    return (
        <div ref={ref} className="stokodiv">
            <h1>مديرية التربية لولاية: بشــار</h1>
            <h1>متوسطة: بوعنيني العماري</h1>
            <h1>المخزون الأولي</h1>
            <div className="print-table">
                <table className="table mt-3">
                    <thead>
                        <tr>
                            <th>المادة</th>
                            <th>المخزون الأولي</th>
                            <th>السعر الأولي</th>
                            <th>المجموع</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(products) && products.map((product) => (
                            <tr key={product.id}>
                                <td>{product.desig_prod}</td>
                                <td>{product.quent_init}</td>
                                <td>{product.prix_init}</td>
                                <td>{product.quent_init * product.prix_init}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default StockInit;
