import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { Form, Table, Button, Pagination, Modal } from 'react-bootstrap';
import './listprod.css';
import { IoClose } from "react-icons/io5";
import Alert from './Alert';

const ListProductinv = ({setshowlistprod,onAddProduct,setAlertVisible,setAlertMessage,setAlertType,typo,handleAddProductToStock,productsbon }) => {
  


  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalbon, setshowModalbon] = useState(false);  
  const [showModalliv, setshowModalliv] = useState(false);  
  
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [alert, setalert] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [prix, setprix] = useState('');
  const [name, setname] = useState('');
  const [id, setid] = useState('');

 
  const doesProductExist = (productId) => {
    return productsbon.some(product => product.id === productId);
    
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [searchName, selectedCategory, currentPage]);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/listprodinv', {
        params: {
          name: searchName,
          category: selectedCategory,
          page: currentPage
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data.data);
      console.log("productttt",productsbon);
      setProducts(response.data.data);
      setTotalPages(response.data.last_page);

    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/catprodinv', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSavebon = () => {
    if(typo===2 || typo==="2" ){
      onAddProduct(id,quantity,name)

    }
    else{
    handleAddProductToStock({name,quantity,id});
    console.log({productName:name,quantity});
    setAlertMessage('  تمت الإضافة  ');
    setAlertType('success');
    setAlertVisible(true);
  }
    setshowModalbon(false);
    setQuantity('1');
    setprix('');
      

  }

  const handleSaveliv = () => {
    if(typo===4 || typo==="4"){
       
      onAddProduct(id,quantity,name,prix)

    }
    else{
    handleAddProductToStock({name,quantity,id,prix,num:'3'});
    console.log({productName:name,quantity});
    setAlertMessage('  تمت الإضافة  ');
    setAlertType('success');
    setAlertVisible(true);
  }
    setshowModalliv(false);
    setQuantity('1');
    setprix('');
    

    

  }

  const handleSelectStockClick = (product) => {
    setSelectedProduct(product);
    if (product.exis === 1 ||  product.exis === "1") {
      fetchStockAndPrice(product.id);
    } else {
      setStock('');
      setPrice('');
      setShowModal(true);
    }
  };


  const handleaddbon = (product) => {
    setSelectedProduct(product);
    setid(product.id);
    setname(product.desig);
    setshowModalbon(true);
    console.log(product);
    
  };

  const handleaddliv = (product) => {
    setSelectedProduct(product);
    setid(product.id);
    setname(product.desig);
    setshowModalliv(true);
    console.log(product);
    
  };

  



  const fetchStockAndPrice = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/get_stock_init/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { quent_init, prix_init,alert } = response.data;
      setStock(quent_init);
      setPrice(prix_init);
      setalert(alert);
      setShowModal(true);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching stock and price:', error);
    }
  };

  const handleSave = async () => {
    try {
      console.log({
        id_prod: selectedProduct.id,
        desid:selectedProduct.desig_prod,
        quent_init: stock,
        prix_init: price
      });

      const token = localStorage.getItem('token');
      await axiosInstance.post('/add_stock', {
        id_prod: selectedProduct.id,
        quent_init: stock,
        prix_init: price,
        alert
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
const aa="zzzz";
      const updatedProduct = {
        desig_prod:selectedProduct?.desig,
        quent_init: stock,
        prix_init: price,
      };

      setAlertMessage('  تم الإضافة بنجاح للمادة');
      setAlertType('success');
      setAlertVisible(true);
     


console.log(updatedProduct);
      // Call the onAddProduct function with the updated product
      onAddProduct(updatedProduct);

      setShowModal(false);

      fetchProducts();
    } catch (error) {
      console.error('Error saving stock and price:', error);
      setAlertMessage('  لم تنجح العملية هناك خطأ   ');
      setAlertType('error');
      setAlertVisible(true);
    }
  };

  return (
    <div className='listprod'>
      
    
       <div className='titrop'>
       <h2> قائمة الأجهزة</h2>
      <IoClose  onClick={() => setshowlistprod(false)}  className='clossse' />
     
      </div>
      <div className="   filtro">
        <Form>
        <div className='divofiltr'>
          <Form.Group controlId="searchName" className='inpoto'>
            
            <Form.Control
              type="text"
              placeholder="البحث باسم المادة"
              value={searchName}
              onChange={handleSearchNameChange}
              className='inpo'
            />
          </Form.Group>
          <Form.Group controlId="categorySelect" className='inpoto'>
            
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">كل الاصناف </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.desig}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          </div>
        </Form>
      </div>
      <Table striped bordered hover className='taboo'>
        <thead>
          <tr>
            
            <th>المادة</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
    {products.map((product) => (
      <tr key={product.id}>
        
        <td>{product.desig}</td>
        <td>
          {typo === 0 ||  typo === "0" ? (
            <Button
              onClick={() => handleSelectStockClick(product)}
              className={product.exis === 0 || product.exis === "0" ? 'btn btn-danger' : 'btn btn-success'}
            >
              {product.exis === 0 || product.exis === "0" ? 'Select Stock' : 'Show Stock'}
            </Button>
          ) : null}


        {typo === 1 || typo === "1" ? (
            <Button
              onClick={doesProductExist(product.id)  ?null: () => handleaddbon(product)}
              className={doesProductExist(product.id) ? 'btn btn-danger' : 'btn btn-success'}
            >
              {doesProductExist(product.id)  ? ' مادة موجودة' :   'أضف إلى الوصل'}
            </Button>
          ) : null}


{typo === 2 || typo === "2" ? (
            <Button
              onClick={doesProductExist(product.id)  ?null: () => handleaddbon(product)}
              className={doesProductExist(product.id) ? 'btn btn-danger' : 'btn btn-success'}
            >
              {doesProductExist(product.id)  ? ' مادة موجودة' :   'أضف إلى الوصل'}
            </Button>
          ) : null}


{typo === 3 || typo === "3" ? (
            <Button
              onClick={doesProductExist(product.id)  ?null: () => handleaddliv(product)}
              className={doesProductExist(product.id) ? 'btn btn-danger' : 'btn btn-success'}
            >
              {doesProductExist(product.id)  ? ' مادة موجودة' :   'أضف إلى الوصل'}
            </Button>
          ) : null}


{typo === 4 || typo === "4"  ? (
            <Button
              onClick={doesProductExist(product.id)  ?null: () => handleaddliv(product)}
              className={doesProductExist(product.id) ? 'btn btn-danger' : 'btn btn-success'}
            >
              {doesProductExist(product.id)  ? ' مادة موجودة' :   'أضف إلى الوصل'}
            </Button>
          ) : null}


        </td>
      </tr>
    ))}



    
  </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.exis === 0 ? 'Select Stock' : 'Show Stock'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" value={selectedProduct?.desig} readOnly />
            </Form.Group>
            <Form.Group controlId="stock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>

            
            <Form.Group controlId="price">
              <Form.Label>alert</Form.Label>
              <Form.Control
                type="number"
                value={alert}
                onChange={(e) => setalert(e.target.value)}
              />
            </Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>




      <Modal show={showModalbon} onHide={() => setshowModalbon(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Quantity0</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" value={selectedProduct?.desig} readOnly />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={quantity}
                min="1" // Set the minimum value to 1
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowModalbon(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSavebon}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>





      <Modal show={showModalliv} onHide={() => setshowModalliv(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Quantity0</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" value={selectedProduct?.desig} readOnly />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={quantity}
                min="1" // Set the minimum value to 1
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>prix</Form.Label>
              <Form.Control
                type="number"
                value={prix}
                min="1" // Set the minimum value to 1
                onChange={(e) => setprix(e.target.value)}
              />
            </Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowModalliv(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveliv}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  );
};

export default ListProductinv;
