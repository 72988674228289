
import './Login.css'; // Make sure to create this CSS file
import {React,useState} from 'react';
import axiosInstance from './axiosConfig';
import { Link,useNavigate } from 'react-router-dom';


function Login() {
  const [email,setLogin]=useState("");
  const [password,setPassword]=useState("");
  const navigate=useNavigate();


 const  ali= async () => {

  await  axiosInstance.get('api/me'
  ,{
      headers:{
          Authorization:'Bearer '+localStorage.getItem("token")
      },
  }
  ).then((f) => {
                                console.log(f);
                           })
                           .catch((error) => {
                                console.log("eeeee");
                   });
   
           }



  const getToken = async () =>{
      await axiosInstance.get("/sanctum/csrf-cookie")
  };
  const loginbutton=async(event)=>{
    
      event.preventDefault();
      try{
          // await getToken();
await axiosInstance.post('/login',{email,password}).then((res) => {
  console.log(res);
  localStorage.setItem("token", res.data.token);
  localStorage.setItem("name", res.data.name);

});
navigate("/app");

      }
      catch(e){
         console.log(e)

      }
      

  }
  return (
    <div className="login-container">
      <h2>تسجيل الدخول</h2>
      <form className="login-form"  onSubmit={loginbutton}>
        <div className="form-group">
          <label htmlFor="email">البريد الإلكتروني</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="أدخل البريد الإلكتروني"
            value={email} onChange={(e)=>setLogin(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">كلمة المرور</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="أدخل كلمة المرور"
            value={password} onChange={(e)=>setPassword(e.target.value)}
          />
        </div>
        <button className="login-button">تسجيل الدخول</button>
      </form>
    </div>
  );
}

export default Login;
