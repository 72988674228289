import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { Form, Table, Button, Pagination, Modal } from 'react-bootstrap';
import './listprod.css';
import { IoClose } from "react-icons/io5";
import Alert from './Alert';
import pako from 'pako';

const ListProduct2 = ({setshowlistprod,onAddProduct,setAlertVisible,setAlertMessage,setAlertType,typo,handleAddProductToStock }) => {
  


  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalbon, setshowModalbon] = useState(false);
  
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [alert, setalert] = useState('');
  const [quantity, setQuantity] = useState('');
  const [name, setname] = useState('');
  const [id, setid] = useState('');

 

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [searchName, selectedCategory, currentPage]);

  const fetchProducts = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axiosInstance.get('/listprod2', {
            params: {
                name: searchName,
                category: selectedCategory,
                page: currentPage
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const encodedData = response.data.data;

        // Decode base64
        const compressedData = atob(encodedData);

        // Convert to Uint8Array for decompression
        const compressedArray = Uint8Array.from(compressedData, c => c.charCodeAt(0));

        // Decompress data
        const decompressedData = pako.inflate(compressedArray, { to: 'string' });

        // Parse JSON
        const jsonData = JSON.parse(decompressedData);

        setProducts(jsonData.data); // Access the 'data' key within the paginated response
        setTotalPages(jsonData.last_page); // Access the 'last_page' key within the paginated response
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/catprod', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSavebon = () => {
    handleAddProductToStock({name,quantity});
    console.log({productName:name,quantity});
    setshowModalbon(false);
    setQuantity('');
    
    setAlertMessage('  تمت الإضافة  ');
    setAlertType('success');
    setAlertVisible(true);

  }

  const handleSelectStockClick = (product) => {
    setSelectedProduct(product);
    if (product.exis === 1) {
      fetchStockAndPrice(product.id);
    } else {
      setStock('');
      setPrice('');
      setShowModal(true);
    }
  };


  const handleaddbon = (product) => {
    setSelectedProduct(product);
    setid(product.id);
    setname(product.desig);
    setshowModalbon(true);
    console.log(product);
    
  };



  const fetchStockAndPrice = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/get_stock_init/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { quent_init, prix_init,alert } = response.data;
      setStock(quent_init);
      setPrice(prix_init);
      setalert(alert);
      setShowModal(true);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching stock and price:', error);
    }
  };

  const handleSave = async () => {
    try {
      console.log({
        id_prod: selectedProduct.id,
        desid:selectedProduct.desig_prod,
        quent_init: stock,
        prix_init: price
      });

      const token = localStorage.getItem('token');
      await axiosInstance.post('/add_stock', {
        id_prod: selectedProduct.id,
        quent_init: stock,
        prix_init: price,
        alert
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
const aa="zzzz";
      const updatedProduct = {
        desig_prod:selectedProduct?.desig,
        quent_init: stock,
        prix_init: price,
      };

      setAlertMessage('  تم الإضافة بنجاح للمادة');
      setAlertType('success');
      setAlertVisible(true);
     


console.log(updatedProduct);
      // Call the onAddProduct function with the updated product
      onAddProduct(updatedProduct);

      setShowModal(false);

      fetchProducts();
    } catch (error) {
      console.error('Error saving stock and price:', error);
      setAlertMessage('  لم تنجح العملية هناك خطأ   ');
      setAlertType('error');
      setAlertVisible(true);
    }
  };

  return (
    <div className='listprod'>
      
    
       <div className='titrop'>
       <h2> قائمة المواد</h2>
      <IoClose  onClick={() => setshowlistprod(false)}  className='clossse' />
     
      </div>
      <div className="   filtro">
        <Form>
        <div className='divofiltr'>
          <Form.Group controlId="searchName" className='inpoto'>
            
            <Form.Control
              type="text"
              placeholder="البحث باسم المادة"
              value={searchName}
              onChange={handleSearchNameChange}
              className='inpo'
            />
          </Form.Group>
          <Form.Group controlId="categorySelect" className='inpoto'>
            
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">كل الاصناف </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.desig}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          </div>
        </Form>
      </div>
      <Table striped bordered hover className='taboo'>
        <thead>
          <tr>
            <th>الرمز</th>
            <th>المادة</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
    {products.map((product) => (
      <tr key={product.id}>
        <td>{product.cod}</td>
        <td>{product.desig}</td>
        <td>
          {typo === 0 ? (
            <Button
              onClick={() => handleSelectStockClick(product)}
              className={product.exis === 0 ? 'btn btn-danger' : 'btn btn-success'}
            >
              {product.exis === 0 ? 'Select Stock' : 'Show Stock'}
            </Button>
          ) : null}


        {typo === 1 ? (
            <Button
              onClick={() => handleaddbon(product)}
              className={product.exis === 0 ? 'btn btn-danger' : 'btn btn-success'}
            >
              {product.exis === 0 ? 'أضف إلى الوصل' : ' مادة موجودة'}
            </Button>
          ) : null}


        </td>
      </tr>
    ))}
  </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages).keys()].map((page) => (
            <Pagination.Item
              key={page + 1}
              active={page + 1 === currentPage}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.exis === 0 ? 'Select Stock' : 'Show Stock'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" value={selectedProduct?.desig} readOnly />
            </Form.Group>
            <Form.Group controlId="stock">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>

            
            <Form.Group controlId="price">
              <Form.Label>alert</Form.Label>
              <Form.Control
                type="number"
                value={alert}
                onChange={(e) => setalert(e.target.value)}
              />
            </Form.Group>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>




      <Modal show={showModalbon} onHide={() => setshowModalbon(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control type="text" value={selectedProduct?.desig} readOnly />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowModalbon(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSavebon}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default ListProduct2;
