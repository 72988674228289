import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
// import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

function Panel({ serviceName, bgColor, onClick, bonnum, bondate }) {
  const panelStyle = {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    padding: '20px',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.3s',
 
    margin: '10px',
    textAlign: 'center',
    color: '#333',
    fontFamily: "'Roboto', sans-serif",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    transform: 'scale(1)',
    overflow:'hidden',
    
    backgroundColor:'whitesmoke',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.backgroundColor = '#e0e0e0';
    e.currentTarget.style.transform = 'scale(1.02)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundColor = 'whitesmoke';
    e.currentTarget.style.transform = 'scale(1)';
  };

  return (
    <div
      className="col-md-4 m-0"
     
     
    >
      <div
       
     
      style={panelStyle}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h1  style={{position:'absolute',top:'0px',left:'0px',backgroundColor:'rebeccapurple',color:'white',padding:'4px 10px'}}>{bonnum}</h1>
      <FontAwesomeIcon icon={faShoppingCart} size="3x" style={{ color: '#007bff', marginBottom: '10px' }} />
      <h4 style={{ margin: '0 0 5px 0' }}>{serviceName}</h4>
      <h4 style={{ margin: '0', fontWeight: '300' }}>{bondate}</h4>
    </div></div>
  );
}

export default Panel;
