// bon_com.jsx
import React from "react";
import './stock_init.css';
import { Table } from 'react-bootstrap';

const Bon_com = React.forwardRef(({ products,selectbonnum,dateselected,selectservice,selectbonsumm }, ref) => {
    return (
        <div ref={ref} className="stokodiv">
         <p className='fs-4  mb-0'>مديرية التربية لولاية: بشــار</p>
            <p className='fs-4  mb-3 '>متوسطة: بوعنيني العماري</p>
            <div className='text-center'>

<h1 className='text-center  fs-1  border border-2 border-dark d-inline px-3 rounded'>  وصل الخروج </h1>

</div>



<div className='container justify-content-between  mb-0 pb-0'>

<div className='col '>
<span className='fs-5'>رقم الوصل :</span>
<span className='fw-bold fs-5'> {selectbonnum}</span>
 </div>   


 <div className='col text-center '>
<span className='fs-5'>المصلحة :</span>
<span className='fw-bold fs-5'> {selectservice}</span>   
 </div>


<div className='col text-start '>
<span className='fs-5'>التاريخ :</span>
<span className='fw-bold fs-5'> {dateselected}</span>   
 </div>





</div>

                                
            <div className="print-table">
                <Table >
                    <thead>
                    <tr className="bg-info fs-4">
                            <th>المادة</th>
                            <th> الكمية</th>
                            
                           
                             
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(products) && products.map((product) => (
                            <tr key={product.id}>
                                <td className="text-end px-1">{product.desig_prod}</td>
                                <td>{product.quent}</td>
                                
                                
                                
                            </tr>
                        ))}
                    </tbody>
                </Table>
                 
            </div>

           
        </div>
    );
});

export default Bon_com;
