import React, {  useRef } from 'react';
import { Table, Button } from 'react-bootstrap';
import FichStock from './print/fichStock';
import ReactToPrint from 'react-to-print';
import './listprod.css';
import { FaPrint } from "react-icons/fa";



const FichedeStock = ({fiche,setshowfiche,selectedstock,selectedname}) => {
  const componentRef = useRef();
  

  
  return (
    <div className='fichee'>
    <p className='fichap'>بطاقة المخزون</p>
      <div className='gg'>
      <div>
     <div className='imo'>
     <span>المادة:</span>
     <span>{selectedname}</span>
     </div>

     <div className='imo'>
     <span>المخزون:</span>
     <span>{selectedstock}</span>
     </div>
     </div>
     
     
     
     <div>            
            <ReactToPrint
                trigger={() => <button variant="primary" className="btn btn-warning fw-bold fs-5"> طباعة  <FaPrint /> </button>}
                content={() => componentRef.current}
            />
             <div style={{ position: 'absolute',display:'none', left: '-9999px' }}>
                <FichStock ref={componentRef}  products={fiche} selectedstock={selectedstock} selectedname={selectedname} />
            </div>
        </div>




     
     </div>
  <div className="">
    <Table  striped bordered hover>
      <thead>
        <tr>
          <th>التاريخ</th>              
          <th>الدخول</th>
          <th> الخروج</th>              
          <th>المخزون</th>
          <th>العملية</th>
          
        </tr>
      </thead>
      <tbody>
        {Array.isArray(fiche) && fiche.map((product) => (
          <tr key={product.id}>
            <td>{product.n==="2" ||product.n===2 ?product.date:null }</td>                
            
            <td>{product.typ==="i"?product.quent:null }</td>
            <td>{product.typ==="o"?product.quent:null }</td>
            
            <td>{product.somme}</td>
            <td>{product.desig}</td>
            
            
          </tr>
        ))}
      </tbody>
    </Table>
    <div className='xx'>
    <Button className="btn btn-succes yy "  onClick={()=>setshowfiche(false)}>خروج </Button>
    </div>
  </div>
  </div>
     );
};

export default FichedeStock;
