// bon_com.jsx
import React from "react";
import './stock_init.css';

const BonCom = React.forwardRef(({ products,selectbonnum,dateselected }, ref) => {
    return (
        <div ref={ref} className="stokodiv">
            <h1>مديرية التربية لولاية: بشــار</h1>
            <h1>متوسطة: بوعنيني العماري</h1>
            <h1>وصل طلب</h1>
            <p >
             رقم الوصل  {selectbonnum}
             </p>
             <p >
             التاريخ:
             {dateselected}
             </p>
            <div className="print-table">
                <table className="table mt-3">
                    <thead>
                        <tr>
                            <th>المادة</th>
                            <th> الكمية</th>
                             
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(products) && products.map((product) => (
                            <tr key={product.id}>
                                <td>{product.desig_prod}</td>
                                <td>{product.quent}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

           
        </div>
    );
});

export default BonCom;
