// // axiosConfig.js
  import axios from 'axios';


const baseURL = 'https://madisql.moktasid.com/public/api'; // Set your base URL here
// const baseURL = 'http://127.0.0.1:8000/api'; // Set your base URL here

const axiosInstance = axios.create({
    baseURL,
});

export default axiosInstance;
