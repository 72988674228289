import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './axiosConfig';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import ListProduct from './ListProduct';
import BonCom from './print/bonCom';

const ListBonCom = () => {
  const [bonComs, setBonComs] = useState([]);
  const [selectedBonCom, setSelectedBonCom] = useState(null);
  const [details, setDetails] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModaldet, setShowDeleteModaldet] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [bonComToDelete, setBonComToDelete] = useState(null);
  const [bonComToDeletedet, setBonComToDeletedet] = useState(null);
  const [dateselected, setdateselected] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [modifiedQuantity, setModifiedQuantity] = useState('');
  const [selectprod, setselectprod] = useState(null);
  const [selectbonnum, setselectbonnum] = useState(null);
  const [selectbonid, setselectbonid] = useState(null);
  const [showListProd, setshowlistprod] = useState(false);
  const [printTrigger, setPrintTrigger] = useState(false);

  const componentRef = useRef();

  const handleAddProductTodet = (product) => {
    setDetails((details) => [product, ...details]);
  };

  const handleSaveaddoneprod = (id_prod, quent, desig_prod) => {
    const token = localStorage.getItem('token');
    axiosInstance.post('/add_one_prod', { id_prod, quent, id_bon: selectbonid }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        handleAddProductTodet({ id: id_prod, quent, desig_prod });
      })
      .catch(error => {
        console.error('There was an error adding the product!', error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/bon_com', { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setBonComs(response.data);
        if (response.data.length > 0) {
          setSelectedBonCom(response.data[0]);
          fetchDetails(response.data[0].id);

          setselectbonid(response.data[0].id)  
          setdateselected(response.data[0].date)
          setselectbonnum(response.data[0].num)
        setSelectedBonCom(response.data[0]);
        
        }
      })
      .catch(error => {
        console.error('There was an error fetching the bon de commande!', error);
      });
  }, []);

  const fetchDetails = (bonComId) => {
    setDetails([]);
    const token = localStorage.getItem('token');
    axiosInstance.get(`/boncomsdet/${bonComId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the details!', error);
      });
  };

  const handleRowClick = (bonCom) => {
    if(selectbonid===bonCom.id){
      
    }
    else{
      setselectbonid(bonCom.id)  
      setdateselected(bonCom.date)
      setselectbonnum(bonCom.num)
    setSelectedBonCom(bonCom);
    fetchDetails(bonCom.id);
    }
  };

  const handleDeleteClick = (bonCom) => {
    setSelectedBonCom(bonCom); // Show details before delete
    setBonComToDelete(bonCom);
    setShowDeleteModal(true);
  };

  const handleDeleteClickdet = (bonCom) => {
    setSelectedBonCom(bonCom); // Show details before delete
    setBonComToDeletedet(bonCom);
    setShowDeleteModaldet(true);
  };

  const confirmDelete = () => {
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/boncom/${bonComToDelete.id}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setBonComs(bonComs.filter(bc => bc.id !== bonComToDelete.id));
        setShowDeleteModal(false);
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
      });
  };

  const confirmDeletedet = () => {
    const token = localStorage.getItem('token');
    axiosInstance.delete(`/boncomdet/${bonComToDeletedet}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setDetails(details.filter(bc => bc.iddet !== bonComToDeletedet));
        setShowDeleteModaldet(false);
      })
      .catch(error => {
        console.error('There was an error deleting the bon de commande!', error);
      });
  };

  const handleModifyClick = (detail) => {
    setSelectedDetail(detail);
    setModifiedQuantity(detail.quent);
    setShowModifyModal(true);
  };

  const confirmModify = () => {
    const token = localStorage.getItem('token');
    axiosInstance.put(`/boncomdet/${selectedDetail.iddet}`, { quent: modifiedQuantity }, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        const updatedDetails = details.map(det =>
          det.iddet === selectedDetail.iddet ? { ...det, quent: modifiedQuantity } : det
        );
        setDetails(updatedDetails);
        setShowModifyModal(false);
      })
      .catch(error => {
        console.error('There was an error updating the bon de commande detail!', error);
      });
  };

  const handlePrintClick = (bonCom) => {
    if (selectedBonCom && selectedBonCom.id === bonCom.id) {
      // If the clicked row is already selected, just trigger print
      setPrintTrigger(true);
    } else {
      // Otherwise, fetch details and then trigger print
      fetchDetails(bonCom.id);
      setSelectedBonCom(bonCom);
      setPrintTrigger(true);
    }
  };

  useEffect(() => {
    if (printTrigger) {
      setPrintTrigger(false);
      document.getElementById('print-btn').click();
    }
  }, [printTrigger, details]);

  return (
    <div className="">

      <div>
        <div style={{ position: 'absolute', display: 'none', left: '-9999px' }}>
          <BonCom ref={componentRef} products={details}  selectbonnum={selectbonnum}  dateselected={dateselected}/>
        </div>
      </div>

      {showListProd && (
        <ListProduct
          handleAddProductToStock={null}
          typo={2}
          setAlertType={null}
          setAlertMessage={null}
          setAlertVisible={null}
          setshowlistprod={setshowlistprod}
          onAddProduct={handleSaveaddoneprod}
          productsbon={details}
        />
      )}

      <h2>وصولات الطلب</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>رقم الوصل</th>
            <th>التاريخ</th>
            <th>الوضعية</th>
            <th>العملية</th>
          </tr>
        </thead>
        <tbody>
          {bonComs.map((bonCom) => (
            <tr key={bonCom.id} onClick={() => handleRowClick(bonCom)} style={{ cursor: 'pointer', backgroundColor: selectedBonCom && selectedBonCom.id === bonCom.id ? 'green' : 'transparent' }}>
              <td>{bonCom.num}</td>
              <td>{bonCom.date}</td>
              <td>{bonCom.desig}</td>
              <td>
                <Button className="btn btn-primary mr-2" onClick={() => handlePrintClick(bonCom)} hidden={selectedBonCom && selectedBonCom.id !== bonCom.id}>طباعة</Button>
                <Button className="btn btn-primary mr-2" onClick={(e) => {  setshowlistprod(true); setSelectedBonCom(bonCom); }} hidden={selectedBonCom && selectedBonCom.id !== bonCom.id}>إضافة مواد</Button>
                {bonCom.iddconf === 1 && <Button className="btn btn-danger" onClick={(e) => { setselectbonnum(bonCom.num); e.stopPropagation(); handleDeleteClick(bonCom); }} hidden={selectedBonCom && selectedBonCom.id !== bonCom.id}>حذف</Button>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedBonCom && (
        <div >
          <h2>تفصيل وصل الطلب رقم {selectedBonCom.num}</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>المادة</th>
                <th>الكمية المطلوبة</th>
                <th>العملية</th>
              </tr>
            </thead>
            <tbody>
              {details.map((detail) => (
                <tr key={detail.id}>
                  <td>{detail.desig_prod}</td>
                  <td>{detail.quent}</td>
                  <td>
                    <Button className="btn btn-danger" onClick={(e) => { setselectprod(detail.desig_prod); handleDeleteClickdet(detail.iddet); }}>حذف</Button>
                    <Button className="btn btn-warning" onClick={() => handleModifyClick(detail)}>تعديل</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف الوصل رقم {selectbonnum}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modify Modal */}
      <Modal show={showModifyModal} onHide={() => setShowModifyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل الكمية</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="modifiedQuantity">
              <Form.Label>الكمية الجديدة</Form.Label>
              <Form.Control
                type="text"
                value={modifiedQuantity}
                onChange={(e) => setModifiedQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModifyModal(false)}>
            إلغاء
          </Button>
          <Button variant="warning" onClick={confirmModify}>
            تعديل
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal Details */}
      <Modal show={showDeleteModaldet} onHide={() => setShowDeleteModaldet(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل أنت متأكد أنك تريد حذف المادة {selectprod}؟
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModaldet(false)}>
            إلغاء
          </Button>
          <Button variant="danger" onClick={confirmDeletedet}>
            حذف
          </Button>
        </Modal.Footer>
      </Modal>

      <ReactToPrint
        trigger={() => <button id="print-btn" style={{ display: 'none' }}>Print</button>}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default ListBonCom;
