import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import './detailpanel.css';
import Error from './Error';

function DetailPanel({ details,setcommandes,commandes,setSelectedCommande }) {
  const [detailss, setDetails] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [quentInput, setQuentInput] = useState('');
  const [showerror, setshowerror] = useState(false);
  const [mesagerror, setmesagerror] = useState();
  const [lastsort, setlastsort] = useState('');
  const [showstat, setshowstat] = useState(false);


  useEffect(() => {
    if (details?.id) {
      const fetchDetails = () => {
        setDetails([]);
        const token = localStorage.getItem('token');
        axiosInstance.get(`/boncomsdetstock/${details.id}`, { headers: { Authorization: `Bearer ${token}` } })
          .then(response => {
            setDetails(response.data);
            console.log(response.data);
          })
          .catch(error => {
            console.error('There was an error fetching the details!', error);
          });
      };
      fetchDetails();
    }
  }, [details]);

  const handleRowClick = (detail) => {
    // setSelectedProduct(detail);
    // handlegetlastsort(detail.id);
  };

  const handleMouafaqClick = (index) => {
    const updatedDetails = [...detailss];
    const currentDetail = updatedDetails[index];
  
    // Check if المخزون الحالي is zero
    if (currentDetail.stock === 0) {
      currentDetail.allowedQuantity = 0;  // If stock is zero, set allowedQuantity to 0
    } else if (currentDetail.quent > currentDetail.stock) {
      currentDetail.allowedQuantity = currentDetail.stock;  // Use المخزون الحالي if it's less than الكمية المطلوبة
    } else {
      currentDetail.allowedQuantity = currentDetail.quent;  // Otherwise, use الكمية المطلوبة
    }
  
    setDetails(updatedDetails);
  };
  

  const handleTadidQuentClick = (detail) => {
    setSelectedProduct(detail);
    
    setShowModal(true);
  };

  const handleSaveCommande = () => {
    // Check if any detail has an empty allowedQuantity
    const invalidDetail = detailss.some(detail => detail.allowedQuantity === null || detail.allowedQuantity === undefined || detail.allowedQuantity === '');

  if (invalidDetail) {
    setmesagerror("أكمل ملأ الجدول");
    setshowerror(true);
    return; // Exit the function without making the API call
  }
  
    // Calculate the sum of all allowedQuantity values
    const totalQuantity = detailss.reduce((sum, detail) => sum + detail.allowedQuantity, 0);
  console.log('total',totalQuantity);
    // Check if the sum of allowedQuantity is zero
    if (totalQuantity === 0) {
      setmesagerror(" لا يمكن حفظ وصل خروج فارغ");
      setshowerror(true);
      return; // Exit the function without making the API call
    }
  
    // Proceed with API call if validation passes
    const productss = detailss.map(detail => ({
      id: detail.id,
      quent: detail.allowedQuantity
    }));
  
    const token = localStorage.getItem('token');
    axiosInstance.post('/bon_sort', {
      id_service: details.id_service,
      id_bon_com: details.id,
      productss
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setSelectedCommande(false);
        setcommandes(commandes.filter(bc => bc.id !== details.id));
      })
      .catch(error => {
        alert(error.response.data.error);
      });
  };
  

  
  const handlegetlastsort = (id_prod) => {
    setlastsort(null);
    const token = localStorage.getItem('token');
    axiosInstance.get('/last_sort_serv', { 
        params: { id_service: details.id_service, id_prod },   
        headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      setshowstat(true);
        const lastsortData = response.data;

        // إذا كانت البيانات المستلمة فارغة، تعيين `lastsort` إلى `null`
        if (!lastsortData || Object.keys(lastsortData).length === 0) {
            setlastsort(null);
        } else {
            const date_bon = new Date(details.date); // تاريخ الوصل الحالي
            const date_lastsort = new Date(lastsortData.date); // تاريخ آخر استفادة

            // حساب الفرق في الأيام
            const timeDiff = Math.abs(date_bon.getTime() - date_lastsort.getTime());
            const days_diff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // تحويل الفرق الزمني من ميلي ثانية إلى أيام

            // حساب معدل الاستهلاك اليومي (كمية/يوم)
            const consumption_rate = lastsortData.quent / days_diff;

            // حساب معدل الوقتي لاستهلاك وحدة (عدد الأيام لكل وحدة واحدة)
            const unit_consumption_rate = consumption_rate > 0 ? Math.ceil(1 / consumption_rate) : 0;

            // تحديث حالة lastsort مع الحقول الجديدة
            setlastsort({
                ...lastsortData,
                days_diff,
                consumption_rate: consumption_rate.toFixed(2), // إختياري: التقريب إلى منزلتين عشريتين
                unit_consumption_rate, // Math.ceil يعيد عددا صحيحا
            });
        }
    })
    .catch(error => {
        console.error('Error fetching last sort data!', error);
    });
};




  const handleAddClick = () => {
    if (quentInput ===null) {
      setmesagerror(' يجب تعيين الكمية ');
      setshowerror(true);
   
  }
  else
  {
    

      if (quentInput <= selectedProduct.stock) {
        const updatedDetails = [...detailss];
        const index = updatedDetails.findIndex(detail => detail.id === selectedProduct.id);
        updatedDetails[index].allowedQuantity = quentInput;
        setDetails(updatedDetails);
        setShowModal(false);
      } else {
        setmesagerror('الكمية المدخلة تجاوزت المخزون الحالي للمادة');
        setshowerror(true);
        
      }
  }
}

  const handleMouafaqAllClick = () => {
    const updatedDetails = detailss.map(detail => ({
      ...detail,
      allowedQuantity: detail.quent > detail.stock ? detail.stock : detail.quent,
    }));
    setDetails(updatedDetails);
    console.log('detailupdate',updatedDetails);
  };

  return (
    <div className="listprode kokolo">
      {showerror  && ( <Error
          mesag={mesagerror}
          setshowerror={setshowerror}
        />)}
      <div className='entete'>
      <span>تفصيل وصل طلب</span>
      <IoClose  onClick={() => setSelectedCommande(false)}  className='clossse' />
      </div>

      <div className='entete'>
      <div >
      <div className='divo'><p>رقم الوصل:</p>   <p>{details?.num}</p>  </div>    
      
      <div className='divo'><p>التاريخ:</p>   <p>{details?.date}</p>  </div>    
      <div className='divo'> <p>المصلحة:</p>      <p>{details?.desig}</p>  </div>    
      
      </div>

      <Button className="mb-3" onClick={handleMouafaqAllClick}>
        موافق على الجميع
      </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>المادة</th>
            <th>المخزون الحالي</th>
            <th>الكمية المطلوبة</th>
            
            <th>الكمية المسموح بها</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {detailss.map((detail, index) => (
            <tr
              key={detail.id}
              onClick={() => handleRowClick(detail)}
            >
              <td>{detail.desig_prod}</td>
              <td>{detail.stock}</td>
              <td style={{ color: detail.stock < detail.quent ? 'red' : 'green' ,fontWeight:'bold'}}>{detail.quent}</td>
              
              <td>{detail.allowedQuantity }</td>
              <td>
                
                <Button className="btn btn-info mr-2 yy" onClick={() => handleMouafaqClick(index)}>موافق</Button>
                <Button className="btn btn-success mr-2 yy" onClick={() =>{setQuentInput(detail.allowedQuantity); handleTadidQuentClick(detail)}}>تحديد الكمية</Button>
                <Button className="btn btn-warning mr-2 yy" onClick={() =>{handlegetlastsort(detail.id); }}>إحصاء</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='booto'>
      <Button className="mb-3 booto" onClick={handleSaveCommande}>
        حفــظ
      </Button>
      </div>

     
     
      {showstat && <div className="panona">{ lastsort === null ? (
        <p>لا يوجد استفادة سابقا</p>)
    :(
    <div  >
        <h4>أخر استفادة من هذه المادة</h4>
        <p><strong>التاريخ :</strong> {lastsort.date} (منذ {lastsort.days_diff} يوم)</p>
        <p><strong>الكمية:</strong> {lastsort.quent}</p>
        
        <p><strong>معدل الاستهلاك اليومي:</strong> {lastsort.consumption_rate} وحدة/يوم</p>
        <p><strong>معدل الوقتي لاستهلاك وحدة:</strong> وحدة كل {lastsort.unit_consumption_rate} أيام</p>
        <p><strong>الكمية المطلوبة من المصالح الأخرى :</strong> 4</p>
       
    </div>
    )
  }
 <Button className="btn btn-primary mr-2" onClick={() => setshowstat(false)}>خروج</Button>
</div>}





      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تحديد الكمية</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="quentInput">
              <Form.Label>الكمية</Form.Label>
              <Form.Control
                type="number"
                value={quentInput}
                onChange={(e) => setQuentInput(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            إلغاء
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            إضافة
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DetailPanel;
