import React, { useState, useEffect , useRef  } from 'react';
import axiosInstance from './axiosConfig';
import { Modal, Button, Form, Pagination } from 'react-bootstrap';
import './stock.css';
import StockInit from './print/StockInit';
import ReactToPrint from 'react-to-print';
import Listproduct from './ListProduct';
import Alert from './Alert';

const Stock = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const componentRef = useRef();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showlistprod, setshowlistprod] = useState(false);
  const [newProduct, setNewProduct] = useState({ name: '', stock: '', price: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');  
  const [filters, setFilters] = useState({ name: '', category: '' });
  const [totalSum, setTotalSum] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchCategories();
    fetchProducts();
    
  }, []);

   const handleAlertClose = () => {
    setAlertVisible(false);
  };

  useEffect(() => {
    fetchProducts();
    
  }, [currentPage, selectedCategory,searchName]);



  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/stock_init', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          name: searchName,
          category: selectedCategory,
          page: currentPage
        }
      });
      const { data, total, totalSum } = response.data;
      setProducts(data || []); // Ensure products is an array
      setTotalPages(response.data.last_page);
      setTotalSum(totalSum);
      
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setProducts([]); // Fallback to empty array on error
    }
  };

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/catprod', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategories(response.data || []); // Ensure categories is an array
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]); // Fallback to empty array on error
    }
  };

  const handleAddProductToStock = (product) => {
    setProducts((prevProducts) => [product, ...prevProducts]);
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleAddProduct = async () => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post('/products', newProduct, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchProducts(currentPage, filters);
      setShowModal(false);
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };


  const handleModifyProduct = (product) => {
    // Implement modify product logic
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchProducts(currentPage, filters);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrint = () => {
    const table = document.querySelector('.print-table').innerHTML;
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body { margin: 0; }
              .no-print { display: none; }
            }
              .table{
              border:2px solid red;
              direction:rtl;
              width:90%;
              }
          </style>
        </head>
        <body>
          <header>
            <h1>Header Content</h1>
          </header>
         
          <footer>
            <p>Footer Content</p>
          </footer>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div>

<div>
            
            <ReactToPrint
                trigger={() => <button variant="primary" className="ml-2 no-print">Print Stock_init</button>}
                content={() => componentRef.current}
            />
             <div style={{ position: 'absolute',display:'none', left: '-9999px' }}>
                <StockInit ref={componentRef}  products={products}/>
            </div>
        </div>



      <h1>المخزون الأولي للمواد</h1>
      <Button variant="primary" onClick={() => setshowlistprod(true)} className="no-print">
        إضافة مخزون
      </Button>
      <Button variant="secondary" onClick={handlePrint} className="ml-2 no-print">
        Print
      </Button>
      <div className="mt-3">
        <Form>
        <div className='divofiltr'>
          <Form.Group controlId="filterName"  className='inputa'>
          
            <Form.Control
              type="text"
              placeholder="Enter product name"
              name="name"
              value={searchName}
              onChange={handleSearchNameChange}
              
            />
          </Form.Group>
          <Form.Group controlId="filterCategory"  className='inputa'>
            
            <Form.Control
              as="select"
              name="category"
              value={selectedCategory}
              onChange={handleCategoryChange}
             
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.desig}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          </div>
        </Form>
      </div>
      <div className="print-table">
        <table className="table mt-3">
          <thead>
            <tr>
              <th>المادة</th>
              <th>المخزون الأولي</th>
              
              <th>السعر الأولي</th>
              <th>المجموع</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(products) && products.map((product) => (
              <tr key={product.id}>
                <td>{product.desig_prod}</td>
                <td>{product.quent_init}</td>
                
                <td>{product.prix_init}</td>
                <td>{product.quent_init * product.prix_init}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Alert message={alertMessage} visible={alertVisible} type={alertType} onClose={handleAlertClose} />
      {showlistprod && <Listproduct typo={0}  setAlertType={setAlertType} setAlertMessage={setAlertMessage}  setAlertVisible={setAlertVisible} setshowlistprod={setshowlistprod} onAddProduct={handleAddProductToStock} />}
      <div>المجموع الكلي: {totalSum}</div>
      {totalPages > 1 && (
        <Pagination>
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </Pagination>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={newProduct.name} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stock</Form.Label>
              <Form.Control type="number" name="stock" value={newProduct.stock} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control type="number" name="price" value={newProduct.price} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleAddProduct}>Add Product</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Stock;
