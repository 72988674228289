import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form, Alert } from 'react-bootstrap';
import axiosInstance from './axiosConfig';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModifyInfoModal, setShowModifyInfoModal] = useState(false);
  const [showModifyPasswordModal, setShowModifyPasswordModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [typuser, settypuser] = useState([]);

  const [selectedtypuser, setselectedtypuser] = useState(null);

  useEffect(() => {
    fetchUsers();
    handletypuser();
  }, []);

  const fetchUsers = async () => {
    // Fetch users from the API
    try {
        const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/users',{ headers: { Authorization: `Bearer ${token}` }});
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };


  const handletypuser = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        const response =await axiosInstance.get(`/typ_user`,{ headers: { Authorization: `Bearer ${token}` }});
      settypuser(response.data);
   
    } catch (error) {
       
    }
    
  };




  const handleDelete = async (userId) => {
    try {
        const token = localStorage.getItem('token');
      await axiosInstance.delete(`/users/${userId}`,{ headers: { Authorization: `Bearer ${token}` }});
      fetchUsers();
      setAlertMessage('User deleted successfully');
      setAlertType('success');
    } catch (error) {
      console.error('Error deleting user', error);
      setAlertMessage('Error deleting user');
      setAlertType('danger');
    }
    setShowDeleteModal(false);
  };

  const handleModifyInfo = async (user) => {
    try {
        const token = localStorage.getItem('token');
      await axiosInstance.put(`/users/${user.id}`, user,{ headers: { Authorization: `Bearer ${token}` }});
      fetchUsers();
      setAlertMessage('User info updated successfully');
      setAlertType('success');
    } catch (error) {
      console.error('Error updating user info', error);
      setAlertMessage('Error updating user info');
      setAlertType('danger');
    }
    setShowModifyInfoModal(false);
  };

  const handleModifyPassword = async (userId, newPassword) => {
    try {
        const token = localStorage.getItem('token');
      await axiosInstance.put(`/userspassword/${userId}`, { password: newPassword },{ headers: { Authorization: `Bearer ${token}` }});
      setAlertMessage('Password updated successfully');
      setAlertType('success');
    } catch (error) {
      console.error('Error updating password', error);
      setAlertMessage('Error updating password');
      setAlertType('danger');
    }
    setShowModifyPasswordModal(false);
  };

  const handleAddUser = async (newUser) => {
    try {
        const token = localStorage.getItem('token');
      await axiosInstance.post('/user', newUser,{ headers: { Authorization: `Bearer ${token}` }});
      fetchUsers();
      setAlertMessage('User added successfully');
      setAlertType('success');
    } catch (error) {
      console.error('Error adding user', error);
      setAlertMessage('Error adding user');
      setAlertType('danger');
    }
    setShowAddUserModal(false);
  };

  const toggleBlockUser = async (user) => {
    try {
        const token = localStorage.getItem('token');
        await axiosInstance.put(
            `/usersactiv/${user.id}`, 
            {}, // You need to pass an empty object if there's no data to be sent in the body
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        fetchUsers();
        setAlertMessage(user.blocked ? 'User unblocked successfully' : 'User blocked successfully');
        setAlertType('success');
    } catch (error) {
        console.error('Error toggling user block status', error);
        setAlertMessage('Error toggling user block status');
        setAlertType('danger');
    }
};
  return (
    <div>
      {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
      <Button variant="primary" onClick={() => setShowAddUserModal(true)}> إضافة مستخدم</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>الإسم</th>
            <th>البريد الالكتروني</th>
            <th>المهنة</th>
            <th>العمليات</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.desig}</td>
              <td>
                <Button variant="danger" onClick={() => { setSelectedUser(user); setShowDeleteModal(true); }}>حذف</Button>
                <Button variant="info" onClick={() => { setSelectedUser(user); setShowModifyInfoModal(true); }}> تغيير</Button>
                <Button variant="warning" onClick={() => { setSelectedUser(user); setShowModifyPasswordModal(true); }}> تغيير كلمة السر</Button>
                <Button variant={user.active===1 || user.active==="1"  ? 'secondary' : 'dark'} onClick={() => toggleBlockUser(user)}>{user.active===1  || user.active==="1" ? 'السماح' : 'منع'}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تأكيد الحذف </Modal.Title>
        </Modal.Header>
        <Modal.Body>هل أنت متأكد من حذف هدا المستخدم?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>No</Button>
          <Button variant="primary" onClick={() => handleDelete(selectedUser.id)}>Yes</Button>
        </Modal.Footer>
      </Modal>

      {/* Modify Info Modal */}
      <Modal show={showModifyInfoModal} onHide={() => setShowModifyInfoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>  تغيير معلومات مستخدم</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>الإسم</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser ? selectedUser.name : ''}
                onChange={e => setSelectedUser({ ...selectedUser, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                value={selectedUser ? selectedUser.email : ''}
                onChange={e => setSelectedUser({ ...selectedUser, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>المهنه </Form.Label>
              <Form.Control
              as="select"
              value={selectedUser ? selectedUser.id_typ : ''}
              onChange={e => setSelectedUser({ ...selectedUser, id_typ: e.target.value })}
            >
              
              {typuser.map((typeuserr) => (
                <option key={typeuserr.id} value={typeuserr.id}>
                  {typeuserr.desig}
                </option>
              ))}
            </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModifyInfoModal(false)}>إلغاء</Button>
          <Button variant="primary" onClick={() => handleModifyInfo(selectedUser)}> حفظ التغيير</Button>
        </Modal.Footer>
      </Modal>

      {/* Modify Password Modal */}
      <Modal show={showModifyPasswordModal} onHide={() => setShowModifyPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تغيير كلمة السر </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>كلمة السر الجديدة </Form.Label>
              <Form.Control
                type="password"
                onChange={e => setSelectedUser({ ...selectedUser, newPassword: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>تأكيد كلمة السر </Form.Label>
              <Form.Control
                type="password"
                onChange={e => setSelectedUser({ ...selectedUser, confirmPassword: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModifyPasswordModal(false)}>إلغاء</Button>
          <Button variant="primary" onClick={() => handleModifyPassword(selectedUser.id, selectedUser.newPassword)}>حفظ </Button>
        </Modal.Footer>
      </Modal>

      {/* Add User Modal */}
      <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>إضافة  مستخدم </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>الإسم</Form.Label>
              <Form.Control
                type="text"
                onChange={e => setSelectedUser({ ...selectedUser, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>البريد الإلكتروني</Form.Label>
              <Form.Control
                type="email"
                onChange={e => setSelectedUser({ ...selectedUser, email: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>كلمة السر</Form.Label>
              <Form.Control
                type="password"
                onChange={e => setSelectedUser({ ...selectedUser, password: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>المهنة </Form.Label>
              <Form.Control
              as="select"
              
              onChange={e => setSelectedUser({ ...selectedUser, id_typ: e.target.value })}
            >
              
              {typuser.map((typeuserr) => (
                <option key={typeuserr.id} value={typeuserr.id}>
                  {typeuserr.desig}
                </option>
              ))}
            </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddUserModal(false)}>إلغاء</Button>
          <Button variant="primary" onClick={() => handleAddUser(selectedUser)}>حفظ </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;
