import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListProduct from './ListProduct';
import Alert from './Alert';
import axiosInstance from './axiosConfig';

const BonCommande = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [products, setProducts] = useState([]);
  
  
  
  const [showListProd, setshowlistprod] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);

 

  const handleAddProductToStock = (product) => {
    setProducts((prevProducts) => [product, ...prevProducts]);
  };

  const handleDeleteProduct = (index) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const handleEditProduct = (product, index) => {
    setEditingProduct({ ...product, index });
  };

  const handleSaveEdit = () => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) =>
        index === editingProduct.index ? editingProduct : product
      )
    );
    setEditingProduct(null);
  };

  const handleSaveCommande = () => {
    console.log(products);
    const token = localStorage.getItem('token');
    axiosInstance.post('/save-commande', { products },{   headers: {
      Authorization: `Bearer ${token}`
    }})
      .then(response => {
        setAlertMessage('Commande saved successfully!');
        setAlertType('success');
        setAlertVisible(true);
      })
      .catch(error => {
        setAlertMessage('Failed to save commande.');
        setAlertType('danger');
        setAlertVisible(true);
      });
  };


  const handleAlertClose = () => {
    setAlertVisible(false);
  };

  

  const handleCancelCommande = () => {
    // Logic to cancel the commande
    setProducts([]);
  };

  return (
    <div className="">
      {showListProd && (
        <ListProduct
          handleAddProductToStock={handleAddProductToStock}
          typo={1}
          setAlertType={setAlertType}
          setAlertMessage={setAlertMessage}
          setAlertVisible={setAlertVisible}
          setshowlistprod={setshowlistprod}
          onAddProduct={handleAddProductToStock}
          productsbon={products}
        />
      )}

      <Alert message={alertMessage} visible={alertVisible} type={alertType} onClose={handleAlertClose} />

      <div>
        <h2>إنشاء وصل طلب جديد </h2>
        <button className="btn btn-primary" onClick={() => setshowlistprod(true)}>
          إضافة مواد
        </button>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>المـــادة </th>
            <th>الكمية</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
              <td>{product.name}</td>
              <td>{product.quantity}</td>
              <td>
                <button className="btn btn-danger me-2" onClick={() => handleDeleteProduct(index)}>
                  حذف
                </button>
                <button className="btn btn-primary" onClick={() => handleEditProduct(product, index)}>
                  تعديل
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-3">
        <button className="btn btn-success me-2" onClick={handleSaveCommande}>
         حفظ الوصل
        </button>
        <button className="btn btn-danger" onClick={handleCancelCommande}>
          إلغاء الوصل
        </button>
      </div>

      {editingProduct && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Product</h5>
                <button type="button" className="btn-close" onClick={() => setEditingProduct(null)}></button>
              </div>
              <div className="modal-body">
                <p>{editingProduct.name}</p>
                <input
                  type="number"
                  className="form-control"
                  value={editingProduct.quantity}
                  onChange={(e) => setEditingProduct({ ...editingProduct, quantity: e.target.value })}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary" onClick={handleSaveEdit}>
                  Save
                </button>
                <button className="btn btn-secondary" onClick={() => setEditingProduct(null)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BonCommande;
