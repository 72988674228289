// bon_com.jsx
import React from "react";
import './stock_init.css';
import { Table } from 'react-bootstrap';

const FichStock = React.forwardRef(({ products,selectedstock,selectedname }, ref) => {
    return (
        <div ref={ref} className="stokodiv">
            <p className='fs-4  mb-0'>مديرية التربية لولاية: بشــار</p>
            <p className='fs-4  mb-3 '>متوسطة: بوعنيني العماري</p>
            <div className='text-center'>

<h1 className='text-center  fs-1  border border-2 border-dark d-inline px-3 rounded'> بطاقة المخزون </h1>

</div>



<div className='container justify-content-between  mb-0 pb-0'>


<div className='col '>
<span className='fs-5'>المادة:</span>
<span className='fw-bold fs-5'> {selectedname}</span>
 </div>

  
 

<div className='col text-start '>
<span className='fs-5'>المخزون :</span>
<span className='fw-bold fs-5'> {selectedstock}</span>
   

 </div>


</div>



            
   
              
            <div className="print-table">
                <Table    >
                    <thead>
                        <tr className="bg-info fs-4">
                            <th>التاريخ</th>
                            <th> الدخول</th>                            
                            <th> الخروج</th>
                            <th> المخزون</th>
                            <th> العملية</th>
                             
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(products) && products.map((product) => (
                            <tr key={product.id}>
                               <td>{product.n===2 || product.n==="2" ?product.date:null }</td>                
            
            <td>{product.typ==="i"?product.quent:null }</td>
            <td>{product.typ==="o"?product.quent:null }</td>            
            <td>{product.somme}</td>
            <td className="text-end px-1">{product.desig}</td>
                                
                                
                                
                            </tr>
                        ))}
                    </tbody>
                </Table>
                 
            </div>

           
        </div>
    );
});

export default FichStock;
