// bon_com.jsx
import React from "react";
import { Table } from 'react-bootstrap';
import './stock_init.css';
import { formatCurrency } from '../formatCurrency ';

const BonLiv = React.forwardRef(({ products,selectbonnum,dateselected,selectbonforns,selectbonsumm }, ref) => {
    return (
        <div ref={ref} className="stokodiv">
         <p className='fs-4  mb-0'>مديرية التربية لولاية: بشــار</p>
            <p className='fs-4  mb-3 '>متوسطة: بوعنيني العماري</p>
            <div className='text-center'>

<h1 className='text-center  fs-1  border border-2 border-dark d-inline px-3 rounded'>  وصل الدخول </h1>

</div>


<div className='container justify-content-between  mb-0 pb-0'>

<div className='col '>
<span className='fs-5'>رقم الوصل :</span>
<span className='fw-bold fs-5'> {selectbonnum}</span>
 </div>   


 <div className='col text-center '>
<span className='fs-5'>نوع الهبة: :</span>
<span className='fw-bold fs-5'> {selectbonforns}</span>   
 </div>


<div className='col text-start '>
<span className='fs-5'>التاريخ :</span>
<span className='fw-bold fs-5'> {dateselected}</span>   
 </div>





</div>


            
            <div className="print-table">
                <Table>
                    <thead>
                        <tr className="bg-info fs-4">
                            <th>المادة</th>
                            <th> الكمية</th>
                            <th> السعر</th>
                            <th> المبلغ</th>
                             
                            
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(products) && products.map((product) => (
                            <tr key={product.id}>
                                <td  className="text-end px-1">{product.desig}</td>
                                <td>{product.quent}</td>
                                <td>{formatCurrency(product.prix)}</td>
                                <td>{formatCurrency(product.quent*product.prix)}</td>
                                
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className="text-start">
                <span className="fs-4">المجمــوع:</span>    
                <span className="fw-bold fs-4 mx-2 ">{formatCurrency(selectbonsumm)}</span>    
                
                
                </div>
            </div>

           
        </div>
    );
});

export default BonLiv;
