import React, { useState } from 'react';

import {  Button  } from 'react-bootstrap';

import './error.css';
import { GrDislike } from "react-icons/gr";

function Error({ mesag,setshowerror }) {
  


  return (
    <div className="errordiv ">
        <div className="titre ">
        رسالة خطأ
        </div>

        <div className="main ">
        <span className="text-danger fs-2 py-3 ">
        <GrDislike />
            </span>
       
        <p className=" text-danger fs-2">
            {mesag}
            </p>

            <Button className=" btn btn-warning" onClick={()=>setshowerror(false)}>
            خروج
            </Button>

            
        </div>

       
        

        
          </div>
  );
}

export default Error;
