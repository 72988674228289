import React, { useState,useEffect } from 'react';
import { Button, Modal, Table, Form, InputGroup } from 'react-bootstrap';
import { IoCloseSharp } from "react-icons/io5";
import Fornis from './Fornis';
import ListProduct from './ListProduct';
import ListProductinv from './ListProductinv';
import axiosInstance from './axiosConfig';
import Alert from './Alert';
import { FaUserCheck } from "react-icons/fa";
import { FaLaptop } from "react-icons/fa";
import { FaPenAlt } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { MdMiscellaneousServices } from "react-icons/md";
import { formatCurrency } from './formatCurrency ';
import Error from './Error';

const BonDeLivraison = () => {
  const [fournisseur, setFournisseur] = useState('');
  const [showFournisseurList, setShowFournisseurList] = useState(false);
  const [products, setProducts] = useState([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', quantity: '' });
  const [namefornis, setnamefornis] = useState('');
  const [idfornis, setidfornis] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [prixx, setprixx] = useState('');
  const [desigg, setdesigg] = useState('');
  const [mesagerror, setmesagerror] = useState();
  
  const [detailscons, setDetailscons] = useState([]);
  const [detailsinv, setDetailsinv] = useState([]);
  const [alertType, setAlertType] = useState('success');
  const [showListProd, setshowlistprod] = useState(false);
  const [showListProdinv, setshowlistprodinv] = useState(false);
  const [showmodalserv, setshowmodalserv] = useState(false);  
  const [editingProduct, setEditingProduct] = useState(null);
  const [numbon, setnumbon] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [showerror, setshowerror] = useState(false);

  const handleAddProduct = () => {
    setProducts([...products, newProduct]);
    setNewProduct({ name: '', price: '', quantity: '' });
    setShowAddProductModal(false);
  };

  
  const handleSavebon = () => {
    // Create a new product object directly
    const newProduct = { name: desigg, prix: prixx, quantity: '1', num: '2' };

    // Update the products list with the new product
    setProducts((prevProducts) => [...prevProducts, newProduct]);

    console.log([...products, newProduct]);

    setshowmodalserv(false);
};

  const handleEditProduct = (product, index) => {
    setEditingProduct({ ...product, index });
  };

  const handleFournisseurSelect = (name) => {
    setFournisseur(name);
    setShowFournisseurList(false);
  };
  const handleAddProductToStock = (product) => {
    setProducts((prevProducts) => [...prevProducts,product ]);
     console.log(products);
  };
  const handleDeleteProduct = (index) => {
    setProducts(products.filter((_, i) => i !== index));
  };



  const handleSaveCommande = () => {
    if (idfornis === "") {
      setmesagerror("يجب تعيين المورد");
      setshowerror(true);
    } else {
      setIsSaving(true); // Disable the button
  
      console.log(products);
      const token = localStorage.getItem('token');
      axiosInstance.post('/save-liv', { idfornis, products }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setAlertMessage('تمت عملية الحفظ');
        setAlertType('success');
        setAlertVisible(true);
        setProducts([]);
        setnamefornis('');
        setnumbon(null);
      })
      .catch(error => {
        setAlertMessage('Failed to save commande.');
        setAlertType('danger');
        setAlertVisible(true);
      })
      .finally(() => {
        setIsSaving(false); // Re-enable the button
      });
    }
  };
  

  const handleAlertClose = () => {
    setAlertVisible(false);
  };

  



  const handleSaveEdit = () => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) =>
        index === editingProduct.index ? editingProduct : product
      )
    );
    setEditingProduct(null);
  };

  
  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      axiosInstance.get('/get_num', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => { 
          setnumbon(response.data);
          console.log("num", response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the num!', error);
        });
    } else {
      console.error('No token found in localStorage!');
    }
  }, [numbon]);


  
  useEffect(() => {
    setDetailscons(products.filter(bc => bc.num === '1'));
  setDetailsinv(products.filter(bc => bc.num === '3'));
 
  
  }, [products]);

  


  const totalSum = products.reduce((sum, product) => sum + product.prix * product.quantity, 0);

  return (
    <div  >

{showListProd && (
        <ListProduct
          handleAddProductToStock={handleAddProductToStock}
          typo={3}
          setAlertType={setAlertType}
          setAlertMessage={setAlertMessage}
          setAlertVisible={setAlertVisible}
          setshowlistprod={setshowlistprod}
          onAddProduct={handleAddProductToStock}
          productsbon={detailscons}
        />
      )}


      
{showListProdinv && (
        <ListProductinv
          handleAddProductToStock={handleAddProductToStock}
          typo={3}
          setAlertType={setAlertType}
          setAlertMessage={setAlertMessage}
          setAlertVisible={setAlertVisible}
          setshowlistprod={setshowlistprodinv}
          onAddProduct={handleAddProductToStock}
          productsbon={detailsinv}
        />
      )}

      <h2 className='titrawi fs-4'>إنشاء وصل الاستلام</h2>
      <div  className=' d-flex mb-3 justify-content-between p-2' style={{flexWrap:"wrap",verticalAlign: "middle",backgroundColor:"yellowgreen"}}  >
      <div className="mb-3" controlId="fournisseur">
          
          <span className='fw-bold fs-4 mx-3'>
           رقم الوصل:
            </span>

            <span className='fw-bold fs-4 mx-3'>
            {numbon}
            </span>                                            
        </div>

        <div className="" style={{verticalAlign: "middle"}} controlId="fournisseur">
          <span className='fw-bold fs-4 mx-3'> المورد : </span >
          <span className='fw-bold fs-4 mx-3'> {namefornis} </span >
            <Button className='btn btn-warning fs-6 ' onClick={() => setShowFournisseurList(true)}>
            <FaUserCheck  className='mx-2'/>  
              قائمة الموردين
            </Button>
           
        </div>
      </div>
      
      {showFournisseurList && (
        <Fornis  setnamefornis={setnamefornis} setidfornis={setidfornis} setShowFournisseurList={setShowFournisseurList} />
          
      )}

    {showerror  && ( <Error
          mesag={mesagerror}
          setshowerror={setshowerror}
        />)}

<Alert message={alertMessage} visible={alertVisible} type={alertType} onClose={handleAlertClose} />
      <Button variant="info" className="mb-3" onClick={() => setshowlistprod(true)}>
      <FaPenAlt className='mx-1' />  
        إضافة المادة
      </Button>

      <Button variant="primary" className="mb-3  mx-1" onClick={() => setshowmodalserv(true)}>
      <MdMiscellaneousServices className='mx-1' />
      
        إضافة خدمة
      </Button>

      <Button variant="secondary" className="mb-3" onClick={() => setshowlistprodinv(true)}>
      <FaLaptop className='mx-1' />  
        إضافة جهاز
      </Button>

      <Table striped bordered hover className='mt-0'>
        <thead>
          <tr>
            
            <th>المــــادة</th>
            <th>الكميـــة</th>
            <th>السعـــر</th>           
            <th>المبلــغ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index}>
            
              <td className='text-end px-2'>{product.name}</td>
              <td>{product.quantity}</td>
              <td>{formatCurrency(product.prix)}</td>
              
              <td>{formatCurrency(product.prix * product.quantity)}</td>
              <td>
              <button className="btn btn-danger me-2" onClick={() => handleDeleteProduct(index)}>
                  حذف
                </button>
                <button className="btn btn-primary" onClick={() => handleEditProduct(product, index)}>
                  تعديل
                </button>
                </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='d-flex justify-content-between px-1'>
      <div>
      <span className='fw-bold fs-5 mx-1'> عدد المواد: </span>
      <span className='fs-5 bg-secondary text-light rounded fw-bold px-2'>   {products.length}</span>
      </div>

      <div>
      <span  className='fw-bold fs-5 mx-1'> المجمــوع:  </span>
      <span   className='fs-5 bg-secondary text-light rounded fw-bold px-2'>   {formatCurrency(totalSum)}</span>
      </div>
      </div>
     
      <div className='mt-2 text-start px-1'>
      <Button variant="success" className="me-2 fw-bold mx-1" onClick={handleSaveCommande}   disabled={products.length === 0 || isSaving}
      > <IoIosSave />   حفــظ</Button>
      <Button variant="danger" className='fw-bold' onClick={()=>setProducts([])}> <IoCloseSharp />     إلغــاء</Button>
      </div>



      {editingProduct && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Product</h5>
                <button type="button" className="btn-close" onClick={() => setEditingProduct(null)}></button>
              </div>
              <div className="modal-body">
                <p>{editingProduct.name}</p>
                <p>الكمية</p>
                <input
                  type="number"
                  className="form-control"
                  value={editingProduct.quantity}
                  onChange={(e) => setEditingProduct({ ...editingProduct, quantity: e.target.value })}
                />
              <p>السعر</p>
                <input
                  type="number"
                  className="form-control"
                  value={editingProduct.prix}
                  onChange={(e) => setEditingProduct({ ...editingProduct, prix: e.target.value })}
                />
              </div>

              
              <div className="modal-footer">
              <button className="btn btn-secondary" onClick={() => setEditingProduct(null)}>
                  إلغــاء
                </button>
                <button className="btn btn-primary" onClick={handleSaveEdit}>
                
                  حفــظ
                </button>
               
              </div>
            </div>
          </div>
        </div>
      )}



<Modal show={showmodalserv} onHide={() => setshowmodalserv(false)}>
        <Modal.Header closeButton>
          <Modal.Title> تعيين الخدمة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <Form.Group controlId="productName">
              <Form.Label> موضوع الخدمة</Form.Label>
              <Form.Control type="text" 
              
              value={desigg}
                min="1" // Set the minimum value to 1
                onChange={(e) => setdesigg(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formQuantity">
              <Form.Label>المبلــغ</Form.Label>
              <Form.Control
                type="number"
                value={prixx}
                min="1" // Set the minimum value to 1
                onChange={(e) => setprixx(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowmodalserv(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSavebon}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>






          </div>
  );
};

export default BonDeLivraison;
