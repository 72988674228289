import React, { useState, useEffect } from 'react';
import Panel from './Panel';
import DetailPanel from './DetailPanel';
import axiosInstance from './axiosConfig';
import './BonDeCommandesEnAttent.css';


function BonDeCommandesEnAttent() {
  const [selectedCommande, setSelectedCommande] = useState(null);
  const [commandes, setcommandes] = useState([]);

  

  
  useEffect(() => {
    const token = localStorage.getItem('token');
    axiosInstance.get('/bon_com_attent', {  
      headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setcommandes(response.data);
      
      })
      .catch(error => {
        console.error('There was an error fetching the bon de commande!', error);
      });
  }, []);

  return (
    
      <div className="row gap-0">
      <h2 className='titrawi fs-4'>  وصولات طلب في الإنتظار</h2>
        {commandes.map((commande) => (
          <Panel
            key={commande.id}
            serviceName={commande.desig}
            bonnum={commande.num}
            id_service={commande.id_service}
            bondate={commande.date}
            // bgColor={red.bgColor}
            onClick={() => setSelectedCommande(commande)}
          />
        ))}
      

      {selectedCommande && (
        <div className="mt-4">
          <DetailPanel details={selectedCommande}    setcommandes={setcommandes}  commandes={commandes} setSelectedCommande={setSelectedCommande}/>
        </div>
      )}
    </div>
  );
}

export default BonDeCommandesEnAttent;
